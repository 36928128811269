import React from "react";

const Error = ({ msg, type }) => {
  return (
    <>
      {type === "text" ? (
        <div className="text-danger mt-2" role="alert">
          {msg}
        </div>
      ) : (
        <div className="alert alert-danger" role="alert">
          {msg}
        </div>
      )}
    </>
  );
};

export default Error;
