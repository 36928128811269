import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Header from "./ShareModules/Layout/Header/Header";
import Index from "./Components/Home/Index";
import Draft from "./Components/Draft/Draft";
import Submission from "./Components/Submission/Submission";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { set_craeteAndUpadteDraft_data_from_local_storage } from "./redux/audio.slice";
import { ToastContainer} from "react-toastify";
import SucessPage from "./Components/SucessPage/SucessPage";
import AudioForSafari from "./Components/AudioRecording/AudioForSafari";
import EmailNPS from "./Components/EmailNPS/EmailNPS";

function App() {
  const disPatch = useDispatch();

  useEffect(() => {
    disPatch(set_craeteAndUpadteDraft_data_from_local_storage());
  }, []);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        closeButton={true}
        toastStyle={{
          backgroundColor: "white",
          color: "black",
        }}
      />

      <Header />
      <Routes>
       
        <Route path="/" element={<Index />} />
        <Route path="/home" element={<Index />} />
        <Route path="/draft" element={<Draft />} />
       
        <Route path="/audio" element={<AudioForSafari />} />
        <Route path="/submission" element={<Submission />} />
        <Route path="/sucess/:id" element={<SucessPage />} />
        <Route path="/emailNPS/:rating/:id" element={<EmailNPS />} />
      </Routes>
   
    </>
  );
}

export default App;
