import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Process from "../CommonComponent/Process/Process";
import "./submission.scss";
import backArrow from "../../assets/image/backArrow.svg";
import structure from "../../assets/image/structure1.svg";
import largeLogo from "../../assets/image/largeLogo.svg";
import { useDispatch } from "react-redux";
import { reset_isSubmited, set_craeteAndUpadteDraft_data_from_local_storage, submission } from "../../redux/audio.slice";
import { useSelector } from "react-redux";
import { checkAmnt, RedirectUser, validEmail } from "../../utils/utils";
import MyBackdropLoader from "../Loaders/MyBackdropLoader";
function Submission() {
  const disPatch = useDispatch();
  const navigate = useNavigate();

  const [details, setDetails] = useState({
    draftEmail: "",
    whatsAppPhone: "",
  });

  const [error, seterror] = useState({
    draftEmail: "",
    whatsAppPhone: "",
  });

  const { submission_status, submission_error, submission_data, caseInfoId ,isSubmited,redirectdTo} =
    useSelector((s) => s.audioState);

  const handleChnage = (e) => {
    if (e.target.name === "whatsAppPhone") {
      if (!checkAmnt(e.target.value)) {
        return;
      }
    }

    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    seterror({
      draftEmail: "",
      whatsAppPhone: "",
    });

    if (details.draftEmail === "") {
      seterror({
        draftEmail: "Enter Email for Receiving Draft",
        // whatsAppPhone: "Enter whatsapp number",
      });

      return;
    }

    if (details.draftEmail === "") {
      seterror({
        ...error,
        draftEmail: "Enter Email for Receiving Draft",
      });
      return;
    }

    if (!validEmail(details.draftEmail)) {
      seterror({
        ...error,
        draftEmail: "Enter Valid Email",
      });
      return;
    }

    // if (details.whatsAppPhone === "") {
    //   seterror({
    //     ...error,
    //     whatsAppPhone: "Enter whatsapp number",
    //   });
    //   return;
    // }

    disPatch(
      submission({
        ...details,
        whatsAppPhone: details?.whatsAppPhone?.length>0 ?`+91${details.whatsAppPhone}`:"",
        caseInfoId,
      })
    );
  };

  
//AFTER SUBMIT THE PAGE WILL REDIRECT TO SUCCESS PAGE
  useEffect(() => {
    if(isSubmited){
      navigate(redirectdTo);
      disPatch(set_craeteAndUpadteDraft_data_from_local_storage())
      disPatch(reset_isSubmited())
    }
 
  }, [isSubmited]);


  //IF NO CASEINFO ID THEN REDIRECT TO HOME PAGE
  useEffect(() => {
    RedirectUser(navigate);
  }, []);

  return (
    <div>
      <MyBackdropLoader value={submission_status === "loading"} />
      <img src={structure} alt="img" className="structure1" />
      <img src={largeLogo} alt="img" className="structure2" />
      <Process type="submission" />
      <section className="submission">
        <form onSubmit={handleSubmit} className="container">
          <div className="row submission-box">
            <div className="col-12">
              <div className="d-md-flex mt-4">
                <h6>Your Email for Receiving Draft: * </h6>
                <div className="form-group">
                  <input
                    type="email"
                    name="draftEmail"
                    value={details.draftEmail}
                    onChange={handleChnage}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="example@example.com"
                  />
                  <label className="label-heading text-danger"
                  style={{padding: "4px", fontSize: "15px"}}
                  >
                    {error.draftEmail}
                  </label>
                </div>
              </div>
              <div className="d-md-flex mt-4">
                <h6>(Optional) WhatsApp Phone:</h6>
                <div className="form-group">
                  <input
                    name="whatsAppPhone"
                    onChange={handleChnage}
                    value={details.whatsAppPhone}
                    type="text"
                    className="form-control"
                    id="exampleInputtext1"
                    aria-describedby="textHelp"
                    placeholder="999999999"
                  />
                  <label className="label-heading text-danger">
                    {error.whatsAppPhone}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5 mt-4">
            <div className="col-12 d-md-flex d-block justify-content-end">
              <Link to="/audio" style={{ marginRight: "10px" }}>
                <button type="button" className="back_btn btn ">
                  <img src={backArrow} aly="img" />
                  Back
                </button>
              </Link>

              <button type="submit" className="btn primary_btn px-5">
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}

export default Submission;
