import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../API";
import { endPoints } from "../API/api_end_points";

export const getAllAudioFiles = createAsyncThunk(
  "getAllAudioFiles",
  async (id) => {
    try {
      const res = await axiosInstance.get(`${endPoints.getAudio}/${id}`);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const onRecordingPauseSendFilesToBackend = createAsyncThunk(
  "onRecordingPauseSendFilesToBackend",
  async (data) => {
    // if(data){
      console.log("data",data);
    try {
      const res = await axiosInstance.post("/audio/merge", data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  // }
  }
);

export const removeAudioFiles = createAsyncThunk(
  "removeAudioFiles",
  async (data) => {
    try {
      const res = await axiosInstance.post(endPoints.deleteAudio, data);

      return { data: res?.data, id: data?.fileId };
    } catch (error) {
      console.log(error);
    }
  }
);

export const uploadAudioFiles = createAsyncThunk(
  "uploadAudioFiles",
  async (data, thunkApi) => {
    try {
      let percentCompleted = 0;
      const res = await axiosInstance.post(endPoints.uploadAudio, data, {
        onUploadProgress: (progressEvent) => {
          percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          thunkApi?.dispatch(setUploadFileProgress(percentCompleted));
        },
      });

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const reorderAudioFiles = createAsyncThunk(
  "reorderAudioFiles",
  async (data) => {
    try {
      const res = await axiosInstance.post(endPoints.reorderAudio, data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const craeteAndUpadteDraft = createAsyncThunk(
  "craeteAndUpadteDraft",
  async (data) => {
    try {
      const res = await axiosInstance.post("case-info/draft", data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const craeteAndUpadteDraftDetails = createAsyncThunk(
  "craeteAndUpadteDraftDetails",
  async (data) => {
    try {
      const res = await axiosInstance.get(
        `case-info/details/${data?.id}`,
        data
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const submission = createAsyncThunk("submission", async (data) => {
  try {
    const res = await axiosInstance.post("case-info/submit", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const ratingName = createAsyncThunk(
  "ratingName",
  async (data) => {
    try {
      const res = await axiosInstance.post("rating-user/create", {ratingName: data.reatingData && data.reatingData.name, caseInfo : data.id, surveyType : data.reatingData !== undefined ? data.reatingData.surveyType : data.surveyType, emailMsg : data.emailMsg, emailRating : data.emailRating});
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const contactUsData = createAsyncThunk(
  "contactUsData",
  async (data) => {
    try {
      const res = await axiosInstance.post("contactus-user/contactUs", {contactUsName : data.contactUsName, contactUsEmail : data.contactUsEmail, contactUsMessage : data.contactUsMessage});
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  getAllAudioFiles_status: "idle",
  getAllAudioFiles_error: null,
  getAllAudioFiles_data: null,

  removeAudioFiles_status: "idle",
  removeAudioFiles_error: null,
  removeAudioFiles_data: null,

  uploadAudioFiles_status: "idle",
  uploadAudioFiles_error: null,
  uploadAudioFiles_data: null,

  audioList: [],
  currentAudio: null,

  reorderAudioFiles_status: "idle",
  reorderAudioFiles_error: null,
  reorderAudioFiles_data: null,

  craeteAndUpadteDraft_status: "idle",
  craeteAndUpadteDraft_error: null,
  craeteAndUpadteDraft_data: null,
  craeteAndUpadteDraft_data_sucess: false,
  caseInfoId: null,

  submission_status: "idle",
  submission_error: null,
  submission_data: null,
  isSubmited: false,
  redirectdTo: null,

  ratingName_status: "idle",
  ratingName_error: null,
  ratingName_data: null,
  ratingName_data_sucess:false,
  isRatingName : false,

  contactUsData_status: "idle",
  contactUsData_error: null,
  isContactUsData : false,

  total_time: 0,
  current_time: -1,

  craeteAndUpadteDraftDetails_status: "idle",
  craeteAndUpadteDraftDetails_error: null,
  craeteAndUpadteDraftDetails_data: null,

  // audio player states
  isPlaying: false,
  isPaused: false,
  isStopped: false,
  isLoading: false,
  isAFileLoaded: false,
  previousWebsurferRef: null,

  recorded_audio: null,
  recorded_audio_url: null,
  recorded_audio_name: null,
  recorded_audio_duration: null,
  recorded_audio_size: null,
  recorded_audio_type: null,
  recorded_audio_id: null,

  global_recording_state: "idle",
  global_left_list_audio_state: "idle",
  global_right_list_audio_state: "idle",

  onRecordingPauseSendFilesToBackend_status: "idle",
  onRecordingPauseSendFilesToBackend_error: null,
  onRecordingPauseSendFilesToBackend_data: null,

  uploadFileProgress: 0,
  recordedFileInRedux: [],
  recording_start_counter:0
};

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    setcounter: (state, { payload }) => {
      state.recording_start_counter = payload;
    },
    setrecordedFileInRedux: (state, { payload }) => {
      state.recordedFileInRedux = payload;
    },
    setUploadFileProgress: (state, { payload }) => {
      state.uploadFileProgress = payload;
    },

    set_onRecordingPauseSendFilesToBackend_data: (state, { payload }) => {
      state.onRecordingPauseSendFilesToBackend_data = payload;
    },
    set_global_recording_state: (state, action) => {
      state.global_recording_state = action.payload;
    },
    set_global_right_list_audio_state: (state, action) => {
      state.global_right_list_audio_state = action.payload;
    },
    set_global_left_list_audio_state: (state, action) => {
      state.global_left_list_audio_state = action.payload;
    },
    set_recorded_audio: (state, { payload }) => {
      console.log(payload);
      state.recorded_audio = payload;

      //
    },
    set_previousWebsurferRef: (state, { payload }) => {
      state.previousWebsurferRef = payload;

      //
    },
    setisAFileLoaded: (state, { payload }) => {
      state.isAFileLoaded = payload;
    },
    addAudioToList: (state, { payload }) => {
      state.audioList.push(payload);
    },
    setCurrentAudio: (state, { payload }) => {
      state.currentAudio = payload;
    },
    reset_craeteAndUpadteDraft_data_sucess: (state, { payload }) => {
      state.craeteAndUpadteDraft_data_sucess = payload;
    },
    set_craeteAndUpadteDraft_data_from_local_storage: (state, { payload }) => {
      let item = localStorage.getItem("draftData");

      state.caseInfoId = localStorage.getItem("caseInfoID");
      state.craeteAndUpadteDraft_data = JSON.parse(item);
    },
    set_current_time: (state, { payload }) => {
      state.current_time = payload;
    },
    reset_isSubmited: (state, { payload }) => {
      state.isSubmited = false;
    },
    handlePlayPauseRedux: (state, { payload }) => {
      const { wavesurferObj } = payload;
      wavesurferObj?.playPause();
    },
  },
  extraReducers: {
    [getAllAudioFiles.pending]: (state, action) => {
      state.getAllAudioFiles_status = "loading";
      state.getAllAudioFiles_error = null;
      state.getAllAudioFiles_data = null;
    },
    [getAllAudioFiles.fulfilled]: (state, { payload }) => {
      state.getAllAudioFiles_status = "idle";
      if (payload?.status === 200) {
        state.getAllAudioFiles_data = payload?.data;
      } else {
        state.getAllAudioFiles_data = [];
      }
    },

    [getAllAudioFiles.rejected]: (state, action) => {
      state.getAllAudioFiles_status = "idle";
      state.getAllAudioFiles_error = "Something went wrong";
      state.getAllAudioFiles_data = null;
    },
    //remove audio file
    [removeAudioFiles.pending]: (state, action) => {
      state.removeAudioFiles_status = "loading";
      state.removeAudioFiles_error = null;
      state.removeAudioFiles_data = null;
    },
    [removeAudioFiles.fulfilled]: (state, { payload }) => {
      state.removeAudioFiles_status = "idle";

      if (payload?.data?.status === 200) {
        if (payload?.id === state.currentAudio?._id) {
          state.currentAudio = null;
        }
        state.getAllAudioFiles_data = payload?.data?.data?.recording_files;
        toast.success("Audio removed successfully");
      } else {
        state.removeAudioFiles_data = [];
      }
    },
    [removeAudioFiles.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.removeAudioFiles_status = "idle";
      state.removeAudioFiles_error = "Something went wrong";
      state.removeAudioFiles_data = null;
    },
    //uploadf file
    [uploadAudioFiles.pending]: (state, action) => {
      state.uploadAudioFiles_status = "loading";
      state.uploadAudioFiles_error = null;
      state.uploadAudioFiles_data = null;
    },
    [uploadAudioFiles.fulfilled]: (state, { payload }) => {
      state.uploadAudioFiles_status = "idle";

      if (payload?.status === 200) {
        state.getAllAudioFiles_data = payload?.data?.recording_files;
        toast.success("Audio uploaded successfully");
      } else {
        state.uploadAudioFiles_data = [];
      }
    },
    [uploadAudioFiles.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.uploadAudioFiles_status = "idle";
      state.uploadAudioFiles_error = "Something went wrong";
      state.uploadAudioFiles_data = null;
    },
    //reorder audio files
    [reorderAudioFiles.pending]: (state, action) => {
      state.reorderAudioFiles_status = "loading";
      state.reorderAudioFiles_error = null;
      state.reorderAudioFiles_data = null;
    },
    [reorderAudioFiles.fulfilled]: (state, { payload }) => {
      state.reorderAudioFiles_status = "idle";
      if (payload?.status === 200) {
        state.getAllAudioFiles_data = payload?.data?.recording_files;
      } else {
        state.reorderAudioFiles_data = [];
      }
    },
    [reorderAudioFiles.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.reorderAudioFiles_status = "idle";
      state.reorderAudioFiles_error = "Something went wrong";
      state.reorderAudioFiles_data = null;
    },

    //careet and upadte drfat
    [craeteAndUpadteDraft.pending]: (state, action) => {
      state.craeteAndUpadteDraft_status = "loading";
      state.craeteAndUpadteDraft_error = null;
      state.craeteAndUpadteDraft_data = null;
      state.craeteAndUpadteDraft_data_sucess = false;
    },
    [craeteAndUpadteDraft.fulfilled]: (state, { payload }) => {
      state.craeteAndUpadteDraft_status = "idle";
      if (payload?.status === 200) {
        state.craeteAndUpadteDraft_data_sucess = true;
        state.craeteAndUpadteDraft_data = payload?.data;
        state.caseInfoId = payload?.data?._id;
        localStorage.setItem("draftData", JSON.stringify(payload?.data));
        localStorage.setItem("caseInfoID", payload?.data?._id);
      } else {
        toast.error(payload?.message);
        state.craeteAndUpadteDraft_data = null;
      }
    },
    [craeteAndUpadteDraft.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.craeteAndUpadteDraft_status = "idle";
      state.craeteAndUpadteDraft_error = "Something went wrong";
      state.craeteAndUpadteDraft_data = null;
    },
    //submission
    [submission.pending]: (state, action) => {
      state.submission_status = "loading";
      state.submission_error = null;
      state.submission_data = null;
      state.submission_data_sucess = false;
      state.isSubmited = false;
    },
    [submission.fulfilled]: (state, { payload }) => {
      state.submission_status = "idle";
      if (payload?.status === 200) {
        state.submission_data_sucess = true;
        state.submission_data = payload?.data;
        toast.success("Submission Successful");
        state.redirectdTo = `/sucess/${payload?.data?._id}`;
        localStorage.clear();
        state.isSubmited = true;
        // window.location.href = "/";
      } else {
        toast.error(payload?.message);
        state.submission_data = [];
      }
    },
    [submission.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.submission_status = "idle";
      state.submission_error = "Something went wrong";
      state.submission_data = null;
    },

     //ratingName
     [ratingName.pending]: (state, action) => {
      state.ratingName_status = "loading";
      state.ratingName_error = null;
      state.ratingName_data = null;
      state.ratingName_data_sucess = false;
      state.isRatingName = false;
    },
    [ratingName.fulfilled]: (state, { payload }) => {
      state.ratingName_status = "idle";
      if (payload?.status === 200) {
        state.ratingName_data_sucess = true;
        state.ratingName_data = payload?.data;
        localStorage.clear();
        state.isRatingName = true;
        toast.success(payload?.message,{
          onClose: () => window.location.href = "/",
        });
      } else {
        toast.error(payload?.message,{
          onClose: () => window.location.href = "/",
        });
        state.ratingName_data = [];
      }
    },
    [ratingName.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.ratingName_status = "idle";
      state.ratingName_error = "Something went wrong";
      state.ratingName_data = null;
    },

    //contactUsData
    [contactUsData.pending]: (state, action) => {
      state.contactUsData_status = "loading";
      state.contactUsData_error = null;
      state.isContactUsData = false;
    },
    [contactUsData.fulfilled]: (state, { payload }) => {
      state.contactUsData_status = "idle";
      if (payload?.status === 200) {
        state.isContactUsData = true;
        toast.success(payload?.message);
      } else {
        toast.error(payload?.message);
      }
    },
    [contactUsData.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.contactUsData_status = "idle";
      state.contactUsData_error = "Something went wrong";
    },


    //get graft details
    [craeteAndUpadteDraftDetails.pending]: (state, action) => {
      state.craeteAndUpadteDraftDetails_status = "loading";
      state.craeteAndUpadteDraftDetails_error = null;
      state.craeteAndUpadteDraftDetails_data = null;
    },
    [craeteAndUpadteDraftDetails.fulfilled]: (state, { payload }) => {
      state.craeteAndUpadteDraftDetails_status = "idle";
      if (payload?.status === 200) {
        state.craeteAndUpadteDraftDetails_data = payload?.data;
      } else {
        state.craeteAndUpadteDraftDetails_data = null;
        state.craeteAndUpadteDraftDetails_error = payload?.message;
      }
    },
    [craeteAndUpadteDraftDetails.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.craeteAndUpadteDraftDetails_status = "idle";
      state.craeteAndUpadteDraftDetails_error = "Something went wrong";
      state.craeteAndUpadteDraftDetails_data = null;
    },
    // send files to backend after record pause
    [onRecordingPauseSendFilesToBackend.pending]: (state, action) => {
      state.onRecordingPauseSendFilesToBackend_status = "loading";
      state.onRecordingPauseSendFilesToBackend_error = null;
      state.onRecordingPauseSendFilesToBackend_data = null;
    },
    [onRecordingPauseSendFilesToBackend.fulfilled]: (state, { payload }) => {
      state.onRecordingPauseSendFilesToBackend_status = "idle";
      if (payload?.status === 200) {
        state.onRecordingPauseSendFilesToBackend_data = payload?.data;
      }
    },
    [onRecordingPauseSendFilesToBackend.rejected]: (state, action) => {
      toast.error("Something went wrong")
      state.onRecordingPauseSendFilesToBackend_status = "idle";
      state.onRecordingPauseSendFilesToBackend_error = "Something went wrong";
      state.onRecordingPauseSendFilesToBackend_data = null;
    },
  },
});

export const {
  addAudioToList,
  setCurrentAudio,
  reset_craeteAndUpadteDraft_data_sucess,
  reset_isSubmited,
  set_craeteAndUpadteDraft_data_from_local_storage,
  set_current_time,
  handlePlayPauseRedux,
  setisAFileLoaded,
  set_global_right_list_audio_state,
  set_previousWebsurferRef,
  set_recorded_audio,
  set_global_recording_state,
  set_global_left_list_audio_state,
  setUploadFileProgress,
  set_onRecordingPauseSendFilesToBackend_data,
  setrecordedFileInRedux,setcounter
} = audioSlice.actions;

export default audioSlice.reducer;