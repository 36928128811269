import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LinearProgress } from "@mui/material";

export default function MyBackdropLoader({ value, redirect, msg, type,progressVal }) {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={value}
      >
 <CircularProgress size={20} color="inherit" />

        {msg?.length > 0 && <span className="mx-2">{msg}</span>}
       
        <br/>
       
        {type === "progress" && (
          <b>{progressVal} %</b>
        )}
      </Backdrop>
    </div>
  );
}
