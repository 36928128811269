import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { craeteAndUpadteDraftDetails, ratingName } from "../../redux/audio.slice";
import "./sucess.scss";
const SucessPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const [reatingName, setReatingName] = useState('')
  const {
    craeteAndUpadteDraftDetails_status,
    craeteAndUpadteDraftDetails_error,
    craeteAndUpadteDraftDetails_data,
    // ratingName_status,
    redirectdTo,
  } = useSelector((s) => s.audioState);

  const data = [
    {
      id: 1,
      name: 'Very Unsatisfied',
      surveyType: 'Onsite'
    },
    {
      id: 2,
      name: 'Unsatisfied',
      surveyType: 'Onsite'
    },
    {
      id: 3,
      name: 'Neutral',
      surveyType: 'Onsite'
    },
    {
      id: 4,
      name: 'Satisfied',
      surveyType: 'Onsite'
    },
    {
      id: 5,
      name: 'Very Satisfied',
      surveyType: 'Onsite'
    },
  ]

  useEffect(() => {
    if (id !== undefined) {
      dispatch(craeteAndUpadteDraftDetails({ id }));
    }
  }, [id]);

  return (
    <div>
      <div className="mt-3 row m-0">
        <div className="container col-md-6 col-sm-12">
          {craeteAndUpadteDraftDetails_status === "loading" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ color: "#138085" }} />
              </Box>
            </>
          ) : (
            <>
              <div className="card sucess_container shadow-lg text-center">
                {craeteAndUpadteDraftDetails_data?.status === "Draft" && (
                  <div className="alert alert-warning" role="alert">
                    <h6>
                      Your submission is not completed yet. Please complete it.
                    </h6>
                  </div>
                )}

                {craeteAndUpadteDraftDetails_data?.status === "Submitted" && (
                  <div className="alert alert-success" role="alert">
                    <h6>
                      Your submission is successful, check your email <span className="mx-2">
                        {craeteAndUpadteDraftDetails_data?.draftEmail}
                      </span>

                    </h6>
                  </div>
                )}

                {craeteAndUpadteDraftDetails_error !== null && (
                  <div className="alert alert-warning" role="alert">
                    <h6>
                      {craeteAndUpadteDraftDetails_error}
                    </h6>
                  </div>
                )}

                {/* <p>Thank you for your submission</p> */}
                <Link to="/">
                  <button className="primary_btn btn">

                    Back to home</button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      {/* {ratingName_status === "loading" ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#138085" }} />
          </Box>
        </>
      ) : ( */}
        <>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ color: "#138085" }} />
            </Box>
          ) : (
            <div className="row m-0 mt-4 pt-4">
            <div className="container col-md-6 col-sm-12 mt-4">
              <p>Rate your experience about using our products</p>
              <p>Product packaging</p>
              <div className="d-flex gap-4 align-items-center justify-content-start">
                {data.map((reatingData, index) => {
                  return (
                    <div className="w-100" key={index} onClick={() => {
                      // setReatingName(reatingData.name);
                      setLoading(true);
                      dispatch(ratingName({ reatingData: reatingData, id: id }));
                      setTimeout(() => {
                        setLoading(false);
                      }, 5000);
                    }}>
                      <div className="text-center py-3 " style={{"cursor":"pointer"}}>
                        <svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="10" cy="10" r="9" stroke={reatingData.id === 1 ? "#D03C2C" : reatingData.id === 2 ? "#CB602C" : reatingData.id === 3 ? "#EBBA43" : reatingData.id === 4 ? "#95C763" : '#77B541'} stroke-width="2" />
                          <circle cx="7.5" cy="7.5" r="1.25" fill={reatingData.id === 1 ? "#D03C2C" : reatingData.id === 2 ? "#CB602C" : reatingData.id === 3 ? "#EBBA43" : reatingData.id === 4 ? "#95C763" : '#77B541'} />
                          <circle cx="12.5" cy="7.5" r="1.25" fill={reatingData.id === 1 ? "#D03C2C" : reatingData.id === 2 ? "#CB602C" : reatingData.id === 3 ? "#EBBA43" : reatingData.id === 4 ? "#95C763" : '#77B541'} />
                          {reatingData.id === 1 ? <path d="M7.02668 13.2727C9.38432 10.7973 11.9784 12.1045 12.9998 13.2954C13.0536 13.358 13.0069 13.4508 12.9244 13.4506L7.09705 13.438C7.01087 13.4379 6.96724 13.3351 7.02668 13.2727Z"
                            fill="#D03C2C"
                            stroke="#D03C2C"
                          /> : reatingData.id === 2 ? <path d="M13.125 14.0845C12.1875 12.8347 9.375 11.2588 6.875 14.071" stroke="#CB602C" stroke-width="2" /> : reatingData.id === 3 ? <path d="M6.25 13.125H13.75" stroke="#EBBA43" stroke-width="2" /> : reatingData.id === 4 ? <path d="M13.125 12.5C12.1875 13.7498 9.375 15.3257 6.875 12.5134" stroke="#95C763" stroke-width="2" /> : <path d="M7.02668 12.6698C9.38432 15.1452 11.9784 13.8379 12.9998 12.6471C13.0536 12.5845 13.0069 12.4917 12.9244 12.4918L7.09705 12.5044C7.01087 12.5046 6.96724 12.6074 7.02668 12.6698Z" fill="#77B541" stroke="#77B541" />}
                        </svg></div>
                      <p className="text-nowrap text-center font-weight-normal">{reatingData.name}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          )}
        </>
      {/* )} */}
    </div>
  );
};

export default SucessPage;
