import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../API";
import { ApiHelperFunction } from "../helpers/apiHelper";

const initialState = {
  status: "idle",
  case_type: [],
  high_court_name:[],
  high_court_location: [],
  submitMsg: "",
  submitStatus: "idle",
  
  getHighCourtLocation_status:'idle',
  getCaseType_status:'idle',
  getHighCourtName_status:'idle',

  

};



export const getCaseType = createAsyncThunk(
  "api/case_type/list",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: `/case_type/list`,
      method: "GET",
    });
    let resData = response?.data;
   
    return resData;
    
  }
);


export const getHighCourtName = createAsyncThunk(
  "high_court/list",
  async (formData, id) => {
    const response = await ApiHelperFunction({
      urlPath: `/high_court/list`,
      method: "POST",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const getHighCourtLocation = createAsyncThunk(
  "api/highcourt/location/list",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: `/highcourt/location/list`,
      method: "POST",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const IndexSlice = createSlice({
  name: "Reference",
  initialState,

  extraReducers: (builder) => {
    builder

      .addCase(getCaseType.pending, (state) => {
        state.getCaseType_status = "loading";
      })
      .addCase(getCaseType.fulfilled, (state, {payload}) => {
        state.getCaseType_status = "idle";
        if(payload?.status===200){
          state.case_type = payload?.data;
        }

       
        return state;
      })
      .addCase(getCaseType.rejected, (state, action) => {
        state.getCaseType_status = "idle";

        return state;
      })
      .addCase(getHighCourtName.pending, (state) => {
        state.getHighCourtName_status = "loading";
      })
      .addCase(getHighCourtName.fulfilled, (state, action) => {
        state.getHighCourtName_status = "idle";
        state.high_court_name = action?.payload?.data;
        return state;
      })
      .addCase(getHighCourtName.rejected, (state, action) => {
        state.getHighCourtName_status = "idle";

        return state;
      })
      .addCase(getHighCourtLocation.pending, (state) => {
        state.getHighCourtLocation_status = "loading";
      })
      .addCase(getHighCourtLocation.fulfilled, (state, action) => {
        state.getHighCourtLocation_status = "idle";
        state.high_court_location = action?.payload?.data;
        return state;
      })
      .addCase(getHighCourtLocation.rejected, (state, action) => {
        state.getHighCourtLocation_status = "idle";

        return state;
      })

      // craete and update draft


  },
});

export default IndexSlice.reducer;
