import React from "react";
import info from "../../../assets/image/info.svg";
import temp from "../../../assets/image/temp.svg";
import recording from "../../../assets/image/recording.svg";
import send from "../../../assets/image/send.svg";
import "./process.scss";
import disinfo from "../../../assets/image/disInfo.svg";
import activetemp from "../../../assets/image/activetemp.svg";
import activeSpeaker from "../../../assets/image/activeSpeaker.svg";
import activeSend from "../../../assets/image/activeSend.svg";
import { Link, useNavigate } from "react-router-dom";
import { RedirectUser } from "../../../utils/utils";
function Process(props) {
  const navigate = useNavigate();

  const handleClick = (link) => {
    RedirectUser(navigate);
  };
  let caseId = localStorage.getItem("caseInfoID");

  return (
    <div className="process">
      <div
        className={
          props.type === "home" ? "text-center active" : "text-center inactive"
        }
      >
        <div className="dash_border">
          <div className={props.type === "home" ? "bg_active" : "bg"}>
            {props.type === "home" ? (
              <img src={info} alt="img" />
            ) : (
              <Link
                to={caseId !== undefined && "/"}
                className={caseId === undefined  || !caseId && "disabled-link"}
              >
                <img src={disinfo} alt="img" />
              </Link>
            )}
          </div>
        </div>
        <h6>Input Case Info</h6>
      </div>
      <div
        className={
          props.type === "draft" ? "text-center active" : "text-center inactive"
        }
      >
        <div className="dash_border">
          <div className={props.type === "draft" ? "bg_active" : "bg"}>
            {props.type === "draft" ? (
              <img src={activetemp} alt="img" />
            ) : (
              <Link
                to={caseId !== undefined && "/draft"}
                className={caseId === undefined || !caseId && "disabled-link"}
              >
                {" "}
                <img src={temp} alt="img" />
              </Link>
            )}
          </div>
        </div>
        <h6>Draft Template</h6>
      </div>
      <div
        className={
          props.type === "audio" ? "text-center active" : "text-center inactive"
        }
      >
        <div className="dash_border">
          <div className={props.type === "audio" ? "bg_active" : "bg"}>
            {props.type === "audio" ? (
              <img src={activeSpeaker} alt="img" />
            ) : (
              <Link to={caseId !== undefined && "/audio"} className={caseId === undefined || !caseId && "disabled-link"}>
                <img src={recording} alt="img" />{" "}
              </Link>
            )}
          </div>
        </div>
        <h6>Audio Recording</h6>
      </div>
      <div
        className={
          props.type === "submission"
            ? "text-center active"
            : "text-center inactive"
        }
      >
        <div className="dash_border">
          <div className={props.type === "submission" ? "bg_active" : "bg"}>
            {props.type === "submission" ? (
              <img src={activeSend} alt="img" />
            ) : (
              <Link
                to={caseId !== undefined && "/submission"}
                className={caseId === undefined || !caseId && "disabled-link"}
              >
                <img src={send} alt="img" />
              </Link>
            )}
          </div>
        </div>
        <h6>Submission</h6>
      </div>
    </div>
  );
}

export default Process;
