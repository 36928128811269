import React, { useEffect, useState } from "react";
import "./Header.scss";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../assets/image/smallLogo.svg";
import avatar from "../../../assets/image/avatar.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { contactUsData } from "../../../redux/audio.slice";
import { useDispatch } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { validEmail } from "../../../utils/utils";


function Header() {
  const location = useLocation();
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const [error, seterror] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setTitle("Input Case Info");
        break;
      case "/home":
        setTitle("Input Case Info");
        break;
      case "/audio":
        setTitle("Audio Recording");
        break;
      case "/draft":
        setTitle("Draft Template");
        break;
      default:
        setTitle("Submission");
        break;
    }

  }, [location?.pathname]);

  const handleSubmit = (e) => {
    e.preventDefault();
    seterror({
      name: "",
      email: "",
      message: ""
    });
    if(name === ""){
      seterror({
        ...error,
        name : "Please enter name!!!!",
      });
      return;
    }
    if(email === ""){
      seterror({
        ...error,
        email : "Please enter email!!!!",
      });
      return;
    }
    if (!validEmail(email)) {
      seterror({
        ...error,
        email: "Enter Valid Email",
      });
      return;
    }
    if(message === ""){
      seterror({
        ...error,
        message : "Please enter message!!!!",
      });
      return;
    }
    
    var data = {
      contactUsName: name,
      contactUsEmail: email,
      contactUsMessage: message
    }
   dispatch(contactUsData(data));
   setShow(false);
  };

  return (
    <>
      <div className="header">
        <Navbar expand="md">
          <Link to="/home">
            <Navbar.Brand>
              <img src={logo} alt="img" />
              <span>Recorder</span>
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="right_header">
            <Navbar.Text>{title}</Navbar.Text>
            {/* {location.pathname === "/draft" ? (
              <Navbar.Text>Draft Template</Navbar.Text>
            ) : location.pathname === "/audio" ? (
              <Navbar.Text>Audio Recording</Navbar.Text>
            ) : location.pathname === "/submission" ? (
              <Navbar.Text>Submission</Navbar.Text>
            ) : location.pathname === "/" ? (
              <Navbar.Text>Input Case Info</Navbar.Text>
            ) : (
              <Navbar.Text>Submission Sucess</Navbar.Text>
            )} */}

            <div className="text-end">
              <button className="btn">
                <img className="avatar" src={avatar} alt="img" />
              </button>

            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="position-fixed top-50 end-0 myContectBtn transform-90">
        <button className="px-3" onClick={() => { handleShow() }}>CONTACT US</button>
      </div>
        <Modal show={show} onHide={handleClose}>
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="mb-3">
              <label for="exampleInputName1" className="form-label">Your Name <span className="text-danger">*</span></label>
              <input 
                type="text" 
                name="name" 
                className="form-control myFormControl" 
                id="exampleInputName1" 
                aria-describedby="nameHelp" 
                required
                onChange={(e) => {
                  setName(e.target.value)
                }}
                placeholder="writtly"
                />
                <label className="label-heading text-danger"
                  style={{padding: "4px", fontSize: "15px"}}
                  >
                    {error.name}
                </label>
            </div>
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">Your Email <span className="text-danger">*</span></label>
              <input 
                type="text" 
                name="email" 
                className="form-control myFormControl" 
                id="exampleInputEmail1" 
                aria-describedby="emailHelp" 
                required
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                placeholder="example@example.com"
                />
                 <label className="label-heading text-danger"
                  style={{padding: "4px", fontSize: "15px"}}
                  >
                    {error.email}
                </label>
            </div>
            <div className="mb-3">
              <label for="exampleInputPassword1" className="form-label">Your message <span className="text-danger">*</span></label>
              <textarea 
                name="message" 
                className="form-control" 
                id="exampleInputMessage1" 
                required
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
                placeholder="XYZ...."
                />
                <label className="label-heading text-danger"
                  style={{padding: "4px", fontSize: "15px"}}
                >
                  {error.message}
                </label>
            </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <div className="myContectBtn submit">
              <Button type="submit">
                Save
              </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
    </>
  );
}

export default Header;
