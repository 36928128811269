import React, {
  createContext,
  createRef,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js";
import { mediaPath } from "../../API";
import {
  set_global_left_list_audio_state,
  removeAudioFiles,
  setCurrentAudio,
  setisAFileLoaded,
  set_current_time,
  set_previousWebsurferRef,
} from "../../redux/audio.slice";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import { AiFillPlayCircle } from "react-icons/ai";
import { convertSecondsToTime } from "../../utils/utils";
import recording1 from "../../assets/image/recording1.svg";
import DeleteModal from "../Modals/DeleteModal";
import MyBackdropLoader from "../Loaders/MyBackdropLoader";
import { IconButton, Slider, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const ListAudioPlayer = ({ quote, index }) => {
  const {
    currentAudio,
    caseInfoId,
    current_time,
    global_recording_state,
    previousWebsurferRef,
    global_right_list_audio_state,
  } = useSelector((state) => state.audioState);
  const dispatch = useDispatch();
  const timelineRef = useRef([]);
  // const [wavesurferObj, setWavesurferObj] = useState();
  const [playing, setPlaying] = useState(true); // to keep track whether audio is currently playing or not // to control the zoom level of the waveform
  const [duration, setDuration] = useState(0); // duration is used to set the default region of selection for trimming the audio
  const [total_duration, settotal_duration] = useState(0);
  const wavesurfer = useRef(null);
  const wavesurferId = `wavesurfer--${index}`;
  const [wavesurferobj, setWaveSurferObj] = useState(null);
  const [file, setFile] = useState(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [deleteModal, setDeleteModaal] = useState(false);
  const openDeleteModal = () => setDeleteModaal(true);
  const closeDeleteModal = () => setDeleteModaal(false);

  const [value, setValue] = useState(0);

  const [loading, setLoading] = useState(false);

  const handlePlayPause = (e) => {
    if (wavesurfer?.current?.isPlaying()) {
      wavesurfer?.current?.pause();
    } else {
      wavesurfer?.current?.play();
    }
    setPlaying(!playing);
  };

  const handleNewDuration = (e) => {
    e.preventDefault();
    wavesurfer.current.pause();
    let temp = (e.target.value * wavesurferobj?.getDuration()) / 100;
    setValue(e.target.value);
    dispatch(set_current_time(temp));
  };

  const removeAudio = () => {
    let payload = {
      caseInfoId: caseInfoId,
      fileId: quote?._id,
    };
    dispatch(removeAudioFiles(payload));
  };

  const loadMusicFile = () => {
    setValue(0);
    setDuration(0);
    settotal_duration(0);
    previousWebsurferRef !== null && previousWebsurferRef?.stop();
    setFile(quote);
    dispatch(setCurrentAudio(quote));
    dispatch(set_current_time(0));
    dispatch(setisAFileLoaded(false));
  };

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: `#${wavesurferId}`,
      waveColor: "grey",
      progressColor: "tomato",
      height: 70,
      cursorWidth: 1,
      cursorColor: "lightgray",
      barWidth: 2,
      normalize: true,
      responsive: true,
      fillParent: true,
    });
    setWaveSurferObj(wavesurfer.current);
    return () => {
      wavesurfer.current.stop();
      setWaveSurferObj(wavesurfer.current);
    };
  }, []);

  useEffect(() => {
    dispatch(set_current_time(-1));
    if (file !== null && wavesurfer.current) {
      setLoading(true);
      if (previousWebsurferRef !== null) {
        previousWebsurferRef?.waveform?.destroy();
        previousWebsurferRef?.stop();
        previousWebsurferRef?.unAll();
      }
      wavesurfer?.current?.load(mediaPath(file?.filename));
      let totalTime = wavesurferobj?.getDuration();
      settotal_duration(Math.floor(totalTime));
      dispatch(set_previousWebsurferRef(wavesurfer?.current));
    }
  }, [file]);

  useEffect(() => {
    if (wavesurferobj !== null) {
      wavesurferobj.on("audioprocess", function () {
        if (wavesurferobj.isPlaying()) {
          let totalTime = wavesurferobj.getDuration();
          let currentTime = wavesurferobj.getCurrentTime();

          setValue(
            (wavesurferobj?.getCurrentTime() / wavesurferobj?.getDuration()) *
              100
          );

          let remainingTime = totalTime - currentTime;

          settotal_duration(Math.floor(totalTime));

          setDuration(Math.floor(currentTime));
          // dispatch(set_current_time(Math.floor(remainingTime)));
        }
      });

      wavesurferobj.on("ready", () => {
        wavesurferobj.play();

        setLoading(false);
        setPlaying(true);
        wavesurferobj.enableDragSelection({}); // to select the region to be trimmed

        // set the duration in local state
      });

      wavesurferobj.on("seek", (e) => {
        dispatch(set_current_time(Math.floor(e)));
      });

      // once audio starts playing, set the state variable to true
      wavesurferobj.on("play", () => {
        setPlaying(false);
        dispatch(set_global_left_list_audio_state("playing"));
      });

      wavesurferobj.on("pause", () => {
        dispatch(set_global_left_list_audio_state("paused"));
      });

      // once audio starts playing, set the state variable to false
      wavesurferobj.on("finish", () => {
        setPlaying(true);
        dispatch(set_global_left_list_audio_state("finished"));
        setValue(Math.floor(wavesurferobj.getCurrentTime()));
        dispatch(setCurrentAudio(null));
        wavesurferobj?.waveform?.destroy();
        wavesurferobj?.stop();
        wavesurferobj?.unAll();
      });

      // wavesurfer.current.on("region-click",(e)=>{
      //   console.log("e",e)
      // })

      wavesurferobj.on("region-click", (region) => {
        const regions = region.wavesurfer.regions.list;
        const keys = Object.keys(regions);
        if (keys.length > 1) {
          regions[keys[0]].remove();
        }
      });
    }
  }, [wavesurferobj, currentAudio]);

  useEffect(() => {
    if (current_time >= 0) {
      currentAudio?._id === quote?._id && wavesurferobj.play(current_time);
    }
  }, [current_time]);

  useEffect(() => {
    if (global_recording_state === "recording") {
      wavesurfer?.current?.pause();
    }
    if (!global_right_list_audio_state) {
      wavesurfer?.current?.pause();
    }
  }, [global_recording_state, global_right_list_audio_state]);

  return (
    <>
      <div
        id={quote.id}
        className={
          currentAudio?._id === quote?._id
            ? "card active_audio_container"
            : "card audioCard "
        }
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            display: currentAudio?._id === quote?._id ? "inline-block" : "none",
          }}
          className="active_audio_inner"
        >
          <div className="audio_number">
            <span className="numbers spanText"> {index + 1}</span>
          </div>
          {loading && currentAudio?._id === quote?._id ? (
            <>
              <div className="text-center p-2">
                <div className="spinner-border" role="status"></div>
              </div>
            </>
          ) : (
            <>
              <div
                style={
                  file === null || loading || currentAudio?._id !== quote?._id
                    ? { display: "none" }
                    : { display: "block" }
                }
                // ref={wavesurferRef.current[index]}
                className="wave_class"
                // id={`waveform_${index}`}
                id={wavesurferId}
              />
              <div
                style={
                  file === null || loading || currentAudio?._id !== quote?._id
                    ? { display: "none" }
                    : { display: "block" }
                }
                className="wave_class"
                ref={timelineRef.current[index]}
                id="wave-timeline"
              />
            </>
          )}
          <div className="slider_div">
            <input
              type="range"
              className="form-range"
              value={value}
              max={"100%"}
              min={"0%"}
              step="any"
              style={{
                backgroundSize: `${
                  (wavesurferobj?.getCurrentTime() /
                    wavesurferobj?.getDuration()) *
                  100
                }% 100%`,
              }}
              onChange={handleNewDuration}
              id="myinput"
            />
          </div>

          <div className="d-flex justify-content-between">
            <button className="btn play_btn" onClick={handlePlayPause}>
              {wavesurfer?.current?.isPlaying() ? (
                <PauseCircleOutlineOutlinedIcon />
              ) : (
                <AiFillPlayCircle />
              )}
            </button>

            <div className="d-flex justify-content-between align-items-center">
              {!wavesurfer?.current?.isPlaying() && (
                <IconButton onClick={openDeleteModal}>
                  <Tooltip title="Delete audio">
                    <DeleteIcon
                      style={{
                        color: "white",
                      }}
                    />
                  </Tooltip>
                </IconButton>
              )}

              <p className="text-white">{convertSecondsToTime(duration)}</p>
            </div>
          </div>
        </div>

        {currentAudio?._id !== quote?._id && (
          <div id={quote.id} className="card audioCard noShadow my-4">
            <span className="numbers spanText"> {index + 1}</span>
            <img src={recording1} alt="img" />

            <div className="d-flex justify-content-between mt-4">
              <button onClick={loadMusicFile} className="btn play_btn">
                {currentAudio?._id === quote?._id ? "Playing" : "Play"}
              </button>
              <button onClick={openDeleteModal} className="btn delete_btn">
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
      <Suspense fallback={<MyBackdropLoader value={true} />}>
        {deleteModal && (
          <DeleteModal
            open={deleteModal}
            handleClose={closeDeleteModal}
            handleDelete={removeAudio}
            text="Delete"
            pText="Do you want to delete this item?"
          />
        )}
      </Suspense>
    </>
  );
};

export default ListAudioPlayer;
