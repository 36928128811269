import React, { useEffect, useRef, useState } from "react";
import Process from "../CommonComponent/Process/Process";
import calender from "../../assets/image/calender.svg";
import btnArrow from "../../assets/image/btnArrow.svg";
import structure from "../../assets/image/structure1.svg";
import largeLogo from "../../assets/image/largeLogo.svg";
import "./Index.scss";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Link, useNavigate } from "react-router-dom";
import { BiError } from "react-icons/bi";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import {
  getCaseType,
  getHighCourtLocation,
  getHighCourtName,
} from "../../redux/IndexSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, Divider, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  craeteAndUpadteDraft,
  reset_craeteAndUpadteDraft_data_sucess,
  set_craeteAndUpadteDraft_data_from_local_storage,
} from "../../redux/audio.slice";
import MyBackdropLoader from "../Loaders/MyBackdropLoader";
import moment from "moment";
import Error from "../Error/Error";
import { validateInput } from "../../utils/utils";

const inputFiledData = [
  {
    name: "case_number",
    type: "input",
    label: "Case Number",
  },
  {
    name: "court_order_date",
    type: "date",
    label: "Court Order Date",
  },
  {
    name: "court_district_name",
    type: "input",
    label: "Court District Name",
  },
  {
    name: "court_ia_number",
    type: "input",
    label: "Court IA Number",
  },
  {
    name: "court_ia_year",
    type: "year",
    label: "Court IA Year",
  },
];

const inputFiledData2 = [
  {
    name: "main_prayer",
    type: "input",
    label: "Main Prayer",
  },
  {
    name: "interim_prayer",
    type: "input",
    label: "Interim Prayer",
  },
  {
    name: "pleadings",
    type: "input",
    label: "Pleadings",
  },
];

function Index() {
  const [startDate, setStartDate] = useState(new Date());
  const [petitionerDetails, setPetitionerDetails] = useState([
    { name: "", address: "", requires_verification: true, phone_number: "" },
  ]);
  const [respodentsDetails, setRespodentsDetails] = useState([
    { name: "", address: "" },
  ]);
  const [showError, setShowError] = useState(false);
  const disPatch = useDispatch();
  const history = useNavigate();

  const {
    craeteAndUpadteDraft_status,
    craeteAndUpadteDraft_data,
    craeteAndUpadteDraft_data_sucess,
  } = useSelector((s) => s.audioState);

  const calenderRef = useRef(null);

  const {
    case_type,
    high_court_name,
    high_court_location,

    getHighCourtName_status,

    getHighCourtLocation_status,
    getCaseType_status,
  } = useSelector((state) => state?.Index);

  useEffect(() => {
    disPatch(getHighCourtName());
    disPatch(getCaseType());
  }, []);

  const [details, setDetails] = useState({
    high_court_name: "",
    high_court_location: "",
    petitioner_name: "",
    petitioner_address: "",
    respondent_name: "",
    respondent_address: "",
    advocate_name: "",
    advocate_code: "",
    counsel_details_address: "",
    hasLowerCourt: true,
    lower_court_name: "",
    case_number: "",
    court_order_date: new Date(),
    court_district_name: "",
    court_ia_number: "",
    court_ia_year: new Date(),
    main_prayer: "",
    interim_prayer: "",
    pleadings: "",
    year: new Date(),
  });

  const [caseType, setCaseType] = useState("");
  const [openYearPicker, setOpenYearPicker] = useState(false);

  let name, value;
  const handleChange = (event) => {
    name = event.target.name;
    value = event.target.value;
    if (event?.target?.type === "checkbox") {
      setDetails({ ...details, [name]: event?.target?.checked });
    } else {
      setDetails({ ...details, [name]: value });
    }
  };
  const dispatch = useDispatch();

  const [CourtName, setCourtName] = useState("");

  const [error, setError] = useState({
    caseType: null,
    high_court_name: null,
    high_court_location: null,
    petitioner_details_error: false,
    respodents_details_error: false,
    advocate_name: null,
    advocate_code: null,
    counsel_details_address: null,
  });

  const submitData = async (event) => {
    event.preventDefault();

    if (validateInput(caseType)) {
      setError({ ...error, caseType: "Please Enter Case Type" });
      return;
    }

    let case_type_id = case_type?.find((item) => item.title === caseType);

    let high_court_name_id = high_court_name?.find(
      (item) => item.title === details.high_court_name
    );

    let high_court_location_id = high_court_location?.find(
      (item) => item.title === details.high_court_location
    );

    let obj = {
      ...details,
      caseInfoId:
        localStorage.getItem("caseInfoID") !== null
          ? localStorage.getItem("caseInfoID")
          : "",
      caseType: case_type_id?._id,
      highCourt: high_court_name_id?._id,
      highCourtLocation:
        high_court_location_id?._id ||
        craeteAndUpadteDraft_data?.highCourtLocation?._id,
      petitioner_details: petitionerDetails,
      respondent_details: respodentsDetails,
      counsel_details_address: details?.counsel_details_address,
      counsel_details_name: details?.advocate_name,
      counsel_details_code: details?.advocate_code,
      filling_date: moment(startDate).format("YYYY-MM-DD"),
      court_ia_year: moment(details.court_ia_year).format("YYYY"),
      year: moment(details.year).format("YYYY"),
    };

    dispatch(craeteAndUpadteDraft(obj));
    setShowError(false);
  };

  const returnCaseTypeID = () => {
    let case_type_id = case_type?.find((item) => item.title === caseType);
    return case_type_id?._id;
  };

  useEffect(() => {
    disPatch(set_craeteAndUpadteDraft_data_from_local_storage());
  }, []);

  useEffect(() => {
    if (craeteAndUpadteDraft_data !== null) {
      setDetails({
        high_court_name: craeteAndUpadteDraft_data?.highCourt?.title,
        high_court_location:
          craeteAndUpadteDraft_data?.highCourtLocation?.title,
        advocate_name: craeteAndUpadteDraft_data?.counsel_details?.name,
        advocate_code: craeteAndUpadteDraft_data?.counsel_details?.code,
        counsel_details_address:
          craeteAndUpadteDraft_data?.counsel_details?.address,

        court_ia_year: craeteAndUpadteDraft_data?.case_details?.court_ia_year,
        hasLowerCourt: craeteAndUpadteDraft_data?.case_details?.hasLowerCourt,
        lower_court_name:
          craeteAndUpadteDraft_data?.case_details?.lowerCourt?.title,
        case_number: craeteAndUpadteDraft_data?.case_details?.case_number,
        court_order_date:
          craeteAndUpadteDraft_data?.case_details?.court_order_date,
        court_district_name:
          craeteAndUpadteDraft_data?.case_details?.court_district_name,
        court_ia_number:
          craeteAndUpadteDraft_data?.case_details?.court_ia_number,
        main_prayer: craeteAndUpadteDraft_data?.case_details?.main_prayer,
        interim_prayer: craeteAndUpadteDraft_data?.case_details?.interim_prayer,
        pleadings: craeteAndUpadteDraft_data?.case_details?.pleadings,
        year: craeteAndUpadteDraft_data?.year?.toString(),
      });

      setCaseType(craeteAndUpadteDraft_data?.caseType?.title);

      setStartDate(craeteAndUpadteDraft_data?.filling_date);
      if (craeteAndUpadteDraft_data?.petitioner_details?.length > 0) {
        setPetitionerDetails(craeteAndUpadteDraft_data?.petitioner_details);
      } else {
        setPetitionerDetails([
          {
            name: "",
            address: "",
            requires_verification: true,
            phone_number: "",
          },
        ]);
      }

      if (craeteAndUpadteDraft_data?.respondent_details?.length > 0) {
        setRespodentsDetails(craeteAndUpadteDraft_data?.respondent_details);
      } else {
        setRespodentsDetails([
          {
            name: "",
            address: "",
          },
        ]);
      }
    }
  }, [craeteAndUpadteDraft_data]);

  useEffect(() => {
    if (craeteAndUpadteDraft_data_sucess) {
      history("/draft");
      dispatch(reset_craeteAndUpadteDraft_data_sucess(false));
    }
  }, [craeteAndUpadteDraft_data_sucess]);

  useEffect(() => {
    let high_court_name_id = high_court_name?.find(
      (item) => item.title === details.high_court_name
    );

    if (high_court_name_id !== undefined) {
      disPatch(getHighCourtLocation({ highCourt_id: high_court_name_id?._id }));
    }
  }, [details.high_court_name]);

  const handlePetitineorChange = (e, index) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setPetitionerDetails([
        ...petitionerDetails.map((item, i) => {
          if (i === index) {
            return { ...item, [name]: checked };
          } else {
            return { ...item, [name]: false };
          }
        }),
      ]);
    } else {
      setPetitionerDetails([
        ...petitionerDetails.map((item, i) => {
          if (i === index) {
            return { ...item, [name]: value };
          }
          return item;
        }),
      ]);
    }
  };

  const handleAddClick = () => {
    setPetitionerDetails([
      ...petitionerDetails,
      { name: "", address: "", requires_verification: false },
    ]);
  };
  const handleRemovePeti = (i) => {
    const list = [...petitionerDetails];
    list.splice(i, 1);
    setPetitionerDetails(list);
  };
  const handleRespoChange = (e, index) => {
    const { name, value } = e.target;

    setRespodentsDetails([
      ...respodentsDetails?.map((item, i) => {
        if (i === index) {
          return { ...item, [name]: value };
        }
        return item;
      }),
    ]);
  };
  const handleAddClickRespo = () => {
    setRespodentsDetails([...respodentsDetails, { name: "", address: "" }]);
  };
  const handleRemoveRespo = (i) => {
    const list = [...respodentsDetails];

    list.splice(i, 1);
    setRespodentsDetails(list);
  };

  const [calenderOpen, setCAlerderOpen] = useState(false);
  const [calenderOpen2, setCAlerderOpen2] = useState(false);
  const [calenderOpen3, setCAlerderOpen3] = useState(false);
  const [selectedCaseTypeIndex, setSelectedCaseTypeIndex] = useState(-1);

  const giveFieldOfSelectedCaseType = (name) => {
    let IsFound = false;

    IsFound = case_type[selectedCaseTypeIndex]?.fields?.includes(name);

    return IsFound;
  };

  // Reset error message on every input
  const ResetInputErros = () => {
    if (caseType?.length > 0) {
      setError({ ...error, caseType: null });
    }
    if (details.high_court_name?.length > 0) {
      setError({ ...error, high_court_name: null });
    }
    if (details.high_court_location?.length > 0) {
      setError({ ...error, high_court_location: null });
    }
    if (details.advocate_name?.length > 0) {
      setError({ ...error, advocate_name: null });
    }
    if (details.advocate_code?.length > 0) {
      setError({ ...error, advocate_code: null });
    }
    if (details.counsel_details_address?.length > 0) {
      setError({ ...error, counsel_details_address: null });
    }
    let petitioner_details_error = false;
    let respodents_details_error = false;

    petitionerDetails?.map((item) => {
      if (item.name?.length === 0) {
        petitioner_details_error = true;
      }
      if (item.address?.length === 0) {
        petitioner_details_error = true;
      }
    });

    petitionerDetails?.map((item) => {
      if (item.name?.length === 0) {
        respodents_details_error = true;
      }
      if (item.address?.length === 0) {
        respodents_details_error = true;
      }
    });

    if (!petitioner_details_error) {
      setError({ ...error, petitioner_details_error: false });
      return;
    }

    if (!respodents_details_error) {
      setError({ ...error, respodents_details_error: false });
      return;
    }
  };

  useEffect(() => {
    ResetInputErros();
  }, [caseType, details, petitionerDetails]);

  useEffect(() => {
    if (petitionerDetails?.length > 1) {
      let allCheckBoxUnselected = false;

      let counter = 0;

      petitionerDetails.map((item) => {
        if (item.requires_verification === false) {
          counter++;
        }
      });

      if (counter === petitionerDetails.length) {
        allCheckBoxUnselected = true;
      }

      if (allCheckBoxUnselected) {
        //setsetPetitionerDetails first item to true
        setPetitionerDetails([
          ...petitionerDetails.map((item, i) => {
            if (i === 0) {
              return { ...item, requires_verification: true };
            }
            return item;
          }),
        ]);
      }
    }
  }, [petitionerDetails]);

  useEffect(() => {
    let index = -1;

    case_type?.map((item, i) => {
      if (item?.title === caseType) {
        index = i;
      }
    });

    setSelectedCaseTypeIndex(index);
  }, [caseType, case_type]);

  // useEffect(() => {
  // setDetails({...details, high_court_location:""})
  // }, [details?.high_court_name])

  return (
    <>
      <MyBackdropLoader
        msg="Creating Draft..."
        value={craeteAndUpadteDraft_status === "loading"}
      />

      <img src={structure} alt="img" className="structure1" />
      <img src={largeLogo} alt="img" className="structure2" />
      <div className="home ">
        <Process type="home" />
        <div className="container ">
          <div className="row ">
            <div className="col-lg-3 my-auto">
              <Tooltip title="Select Case Type">
                <h6 className="important">Select Case Type:</h6>
              </Tooltip>
            </div>
            <div className="col-lg-9 header_search case_type_search">
              <Stack spacing={2}>
                <Autocomplete
                  className="form-inline my-2 my-lg-0 relative"
                  id="free-solo-demo"
                  freeSolo
                  name="caseType"
                  onChange={(event, newValue) => {
                    setCaseType(newValue);
                  }}
                  value={caseType}
                  options={case_type?.map((option) => option?.title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            <button className="btn ">
                              {getCaseType_status === "loading" ? (
                                <>
                                  {" "}
                                  <CircularProgress size={20} color="inherit" />
                                </>
                              ) : (
                                <SearchIcon style={{ fontWeight: "200" }} />
                              )}
                            </button>
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Stack>

              {error.caseType !== null && (
                <Error type="text" msg={`Select Case Type `} />
              )}
            </div>
          </div>

          {(giveFieldOfSelectedCaseType("highCourt") ||
            giveFieldOfSelectedCaseType("highCourtLocation") ||
            giveFieldOfSelectedCaseType("filling_date")) && (
            <>
              {
                <div className="row ">
                  <div className="col-lg-3 my-auto">
                    <h6>Cause Title</h6>
                  </div>
                  <div className="col-lg-9 border_left">
                    {giveFieldOfSelectedCaseType("highCourt") && (
                      <div className="d-md-flex  d-block w-100 justify-content-between cause">
                        <Tooltip title="Select High Court Name">
                          <p className="">Select High Court Name</p>
                        </Tooltip>
                        <Stack
                          spacing={2}
                          sx={{ width: 300 }}
                          className="header_search case_type_search w-100"
                        >
                          <Autocomplete
                            className="form-inline my-2 my-lg-0 relative"
                            id="free-solo-demo"
                            freeSolo
                            name="highCourt"
                            style={{
                              display: giveFieldOfSelectedCaseType("highCourt")
                                ? "block"
                                : "none",
                            }}
                            value={details?.high_court_name}
                            onChange={(event, newValue) => {
                              setDetails({
                                ...details,
                                high_court_name: newValue,
                              });
                            }}
                            options={high_court_name?.map(
                              (option) => option?.title
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=""
                                name="high_court_name"
                                sx={{ pl: 1 }}
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                  endAdornment: (
                                    <React.Fragment>
                                      <button className="btn ">
                                        {getHighCourtName_status ===
                                        "loading" ? (
                                          <>
                                            {" "}
                                            <CircularProgress
                                              size={20}
                                              color="inherit"
                                            />
                                          </>
                                        ) : (
                                          <SearchIcon
                                            style={{ fontWeight: "200" }}
                                          />
                                        )}
                                      </button>
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                          {error.high_court_name !== null && (
                            <Error
                              type="text"
                              msg={`Please Select High Court Name`}
                            />
                          )}
                        </Stack>
                      </div>
                    )}

                    {giveFieldOfSelectedCaseType("highCourtLocation") && (
                      <div className="d-md-flex d-block w-100 justify-content-between mt-4 relative">
                        <Tooltip title="Select High Court Location">
                          <p className="">Select High Court Location</p>
                        </Tooltip>
                        <Stack
                          spacing={2}
                          sx={{ width: 300 }}
                          className="header_search case_type_search w-100 "
                        >
                          <Autocomplete
                            className="form-inline my-2 my-lg-0 relative"
                            id="free-solo-demo"
                            freeSolo
                            options={high_court_location?.map(
                              (option) => option?.title
                            )}
                            value={details?.high_court_location}
                            onChange={(event, newValue) => {
                              setDetails({
                                ...details,
                                high_court_location: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                sx={{ pl: 1 }}
                                {...params}
                                label=""
                                name="high_court_location"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                  endAdornment: (
                                    <React.Fragment>
                                      <button className="btn ">
                                        {getHighCourtLocation_status ===
                                        "loading" ? (
                                          <>
                                            {" "}
                                            <CircularProgress
                                              size={20}
                                              color="inherit"
                                            />
                                          </>
                                        ) : (
                                          <SearchIcon
                                            style={{ fontWeight: "200" }}
                                          />
                                        )}
                                      </button>
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                          {error.high_court_location !== null && (
                            <Error
                              type="text"
                              msg={`Please Select High Court Location`}
                            />
                          )}
                        </Stack>
                      </div>
                    )}

                    
                  </div>
                </div>
              }
            </>
          )}

          {giveFieldOfSelectedCaseType("petitioner_details") && (
            <div className="row " id="petitioner_details">
              <div className="col-lg-3 my-auto">
                <h6>Petitioner(s) Details</h6>
              </div>

              <div className="col-lg-9 border_left">
                {petitionerDetails?.map((x, i) => (
                  <div key={i}>
                    {i !== 0 && <Divider sx={{ m: 2 }} />}
                    <div className="d-md-flex d-block w-100 justify-content-between relative">
                      <Tooltip title="Enter Petitioner’s Name">
                        <p className="">
                          Petitioner’s Name{i !== 0 && `(${i})`}
                        </p>
                      </Tooltip>

                      <div className="form-inline my-2 my-lg-0 relative form-2">
                        <input
                          className="form-control mr-sm-2"
                          type="text"
                          aria-label="text"
                          name="name"
                          value={x?.name}
                          onChange={(e) => handlePetitineorChange(e, i)}
                        />
                        {error.petitioner_details_error && (
                          <Error
                            type="text"
                            msg={`Enter Petitioner’s Name ${i > 0 ? i : ""}`}
                          />
                        )}
                      </div>

                      {petitionerDetails?.length !== 1 && (
                        <button
                          className="btn add_btn2 ml-2 mt-2"
                          onClick={() => handleRemovePeti(i)}
                        >
                          <Tooltip title="Remove Petitioner">
                            <RemoveCircleIcon
                              className="shadow"
                              style={{
                                fontSize: "30px",
                                color: "gray",
                              }}
                            />
                          </Tooltip>
                        </button>
                      )}

                      {i === petitionerDetails?.length - 1 && (
                        <button
                          className="btn add_btn ml-2"
                          onClick={handleAddClick}
                        >
                          <Tooltip title="Add more Petitioner">
                            <img
                              className="shadow"
                              src="/img/plus.svg"
                              alt="img"
                            />
                          </Tooltip>
                        </button>
                      )}
                    </div>

                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Enter Petitioner’s Address">
                        <p className="">
                          Petitioner’s Address{i !== 0 && `(${i})`}
                        </p>
                      </Tooltip>

                      <div className="form-inline my-2 my-lg-0 relative form-2">
                        <input
                          className="form-control mr-sm-2"
                          type="search"
                          aria-label="Search"
                          name="address"
                          value={x?.address}
                          onChange={(e) => handlePetitineorChange(e, i)}
                        />
                        {error.petitioner_details_error && (
                          <Error
                            type="text"
                            msg={`Enter Petitioner’s Adress ${i > 0 ? i : ""}`}
                          />
                        )}
                      </div>
                    </div>

                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Enter Petitioner’s Address">
                        <p className="">
                          Petitioner’s Phone number{i !== 0 && `(${i})`}
                        </p>
                      </Tooltip>

                      <div className="form-inline my-2 my-lg-0 relative form-2">
                        <input
                          className="form-control mr-sm-2"
                          type="search"
                          aria-label="Search"
                          name="phone_number"
                          value={x?.phone_number}
                          onChange={(e) => handlePetitineorChange(e, i)}
                        />
                        {error.petitioner_details_error && (
                          <Error
                            type="text"
                            msg={`Enter Petitioner’s Adress ${i > 0 ? i : ""}`}
                          />
                        )}
                      </div>
                    </div>

                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Check for verification">
                        <p className="">Verification{i !== 0 && `(${i})`}</p>
                      </Tooltip>

                      <div className="form-inline my-2 my-lg-0 relative form-2">
                        <Checkbox
                          type="checkbox"
                          name="requires_verification"
                          checked={x?.requires_verification}
                          onChange={(e) => handlePetitineorChange(e, i)}
                        />
                        {x?.name?.length === 0 && showError && (
                          <span className="d-flex pl-5">
                            <BiError
                              style={{
                                marginRight: "3px",
                                marginTop: "10px",
                                color: "red",
                              }}
                            />
                            <Error
                              type="text"
                              msg={`Enter Petitioner’s Name ${i > 0 ? i : ""}`}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {giveFieldOfSelectedCaseType("respondent_details") && (
            <div className="row ">
              <div className="col-lg-3 my-auto">
                <h6>Respondent(s) Details</h6>
              </div>
              <div className="col-lg-9 border_left">
                {respodentsDetails?.map((y, i) => (
                  <div key={i}>
                    {i !== 0 && <Divider sx={{ m: 2 }} />}
                    <div className="d-md-flex d-block w-100 justify-content-between relative">
                      <Tooltip title="Enter Respondent’s Name">
                        <p className="">
                          Respondent’s Name{i !== 0 && `(${i})`}
                        </p>
                      </Tooltip>

                      <div className="form-inline my-2 my-lg-0 relative form-2">
                        <input
                          className="form-control mr-sm-2"
                          type="text"
                          aria-label="text"
                          name="name"
                          value={y.name}
                          onChange={(e) => handleRespoChange(e, i)}
                        />
                        {y?.name?.length === 0 && showError && (
                          <span className="d-flex pl-5">
                            <BiError
                              style={{
                                marginRight: "3px",
                                marginTop: "10px",
                                color: "red",
                              }}
                            />
                            <Error
                              type="text"
                              msg={`Enter Respondent’s Name ${i > 0 ? i : ""}`}
                            />
                          </span>
                        )}
                      </div>
                      {respodentsDetails?.length !== 1 && (
                        <button
                          className="btn add_btn2 ml-2 mt-2"
                          onClick={() => handleRemoveRespo(i)}
                        >
                          <Tooltip title="Remove Respondent">
                            <RemoveCircleIcon
                              className="shadow"
                              style={{
                                fontSize: "30px",
                                color: "gray",
                              }}
                            />
                          </Tooltip>
                        </button>
                      )}

                      {i === respodentsDetails?.length - 1 && (
                        <button
                          className="btn add_btn ml-2"
                          onClick={handleAddClickRespo}
                        >
                          <Tooltip title="Add Respondent">
                            <img
                              className="shadow"
                              src="/img/plus.svg"
                              alt="img"
                            />
                          </Tooltip>
                        </button>
                      )}
                    </div>
                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Enter Respondent’s Address">
                        <p className="">
                          Respondent’s Address{i !== 0 && `(${i})`}
                        </p>
                      </Tooltip>

                      <div className="form-inline my-2 my-lg-0 relative form-2">
                        <input
                          className="form-control mr-sm-2"
                          type="search"
                          aria-label="Search"
                          name="address"
                          value={y?.address}
                          onChange={(e) => handleRespoChange(e, i)}
                        />
                        {y?.address?.length === 0 && showError && (
                          <span className="d-flex pl-5">
                            <BiError
                              style={{
                                marginRight: "3px",
                                marginTop: "10px",
                                color: "red",
                              }}
                            />
                            <Error
                              type="text"
                              msg={`Enter Respondent’s address ${
                                i > 0 ? i : ""
                              }`}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {(giveFieldOfSelectedCaseType("counsel_details_name") ||
            giveFieldOfSelectedCaseType("counsel_details_address") ||
            giveFieldOfSelectedCaseType("counsel_details_code")) && (
            <>
              <div className="row ">
                <div className="col-lg-3 my-auto">
                  <h6>Counsel</h6>
                </div>
                <div className="col-lg-9 border_left">
                  {giveFieldOfSelectedCaseType("counsel_details_name") && (
                    <div className="d-md-flex d-block w-100 justify-content-between">
                      <Tooltip title="Enter Advocate’s Name ">
                        <p className=""> Advocate’s Name</p>
                      </Tooltip>

                      <form className="form-inline my-2 my-lg-0 relative form-2">
                        <input
                          className="form-control mr-sm-2"
                          type="search"
                          aria-label="Search"
                          name="advocate_name"
                          value={details?.advocate_name}
                          onChange={handleChange}
                        />
                        {error.advocate_name !== null && (
                          <Error
                            type="text"
                            msg={`Please Select Advocate Name`}
                          />
                        )}
                      </form>
                    </div>
                  )}

                  {giveFieldOfSelectedCaseType("counsel_details_address") && (
                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Enter Advocate’s code ">
                        <p className=""> Advocate’s Code</p>
                      </Tooltip>

                      <form className="form-inline my-2 my-lg-0 relative form-2">
                        <input
                          className="form-control mr-sm-2"
                          type="search"
                          aria-label="Search"
                          name="advocate_code"
                          value={details?.advocate_code}
                          onChange={handleChange}
                        />

                        {error.advocate_code !== null && (
                          <Error
                            type="text"
                            msg={`Please Select Advocate code`}
                          />
                        )}
                      </form>
                    </div>
                  )}

                  {giveFieldOfSelectedCaseType("counsel_details_code") && (
                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Enter Advocate’s Address ">
                        <p className="">Advocate’s Address</p>
                      </Tooltip>

                      <form className="form-inline my-2 my-lg-0 relative form-2">
                        <input
                          className="form-control mr-sm-2"
                          type="search"
                          aria-label="Search"
                          name="counsel_details_address"
                          value={details?.counsel_details_address}
                          onChange={handleChange}
                        />
                        {error.counsel_details_address !== null && (
                          <Error
                            type="text"
                            msg={`Please Select Advocate's Address`}
                          />
                        )}
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {(giveFieldOfSelectedCaseType("filling_date") ||
            giveFieldOfSelectedCaseType("year")) && (
            <>
              <div className="row ">
                <div className="col-lg-3 my-auto">
                  <h6>Filing Dates</h6>
                </div>
                <div className="col-lg-9 border_left">
                  {giveFieldOfSelectedCaseType("filling_date") && (
                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Enter Filing Date ">
                        <p className="">Filing Date</p>
                      </Tooltip>

                      <div className="form-inline my-2 my-lg-0 w-100 header_search header_search_calender relative form-2">
                        <LocalizationProvider
                          style={{
                            width: "100%",
                          }}
                          className="w-100"
                          dateAdapter={AdapterDateFns}
                        >
                          <DatePicker
                            value={startDate}
                            open={calenderOpen}
                            onOpen={() => setCAlerderOpen(true)}
                            onClose={() => setCAlerderOpen(false)}
                            ref={calenderRef}
                            onChange={(newValue) => {
                              console.log("newValue", newValue);
                              setStartDate(newValue);
                            }}
                            components={{
                              OpenPickerIcon: () => {
                                return (
                                  <button
                                    className="btn "
                                    onClick={() => {
                                      setCAlerderOpen(!calenderOpen);
                                    }}
                                  >
                                    <img src={calender} alt="img" />
                                  </button>
                                );
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                className="ml-2"
                                onClick={() => setCAlerderOpen(true)}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <button className="btn absolute">
                          {/* <img src={calender} alt="img" /> */}
                        </button>
                        {(startDate?.length === 0 || startDate === null) &&
                          showError && (
                            <span className="d-flex pl-5">
                              <BiError
                                style={{
                                  marginRight: "3px",
                                  marginTop: "10px",
                                  color: "red",
                                }}
                              />
                              <Error type="text" msg={`Please select a date`} />
                            </span>
                          )}
                      </div>
                    </div>
                  )}

                  {giveFieldOfSelectedCaseType("year") && (
                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Enter Year ">
                        <p className="">Year</p>
                      </Tooltip>

                      <div className="form-inline my-2 my-lg-0 w-100 header_search header_search_calender relative form-2">
                        <LocalizationProvider
                          style={{
                            width: "100%",
                          }}
                          className="w-100 shadow-lg"
                          dateAdapter={AdapterDateFns}
                        >
                          <DatePicker
                            value={details?.year}
                            views={["year"]}
                            open={openYearPicker}
                            onOpen={() => {
                              setOpenYearPicker(true);
                            }}
                            onClose={() => {
                              setOpenYearPicker(false);
                            }}
                            ref={calenderRef}
                            name="year"
                            onChange={(newValue) => {
                              setDetails({
                                ...details,
                                year: newValue,
                              });
                            }}
                            components={{
                              OpenPickerIcon: () => {
                                return (
                                  <button
                                    className="btn "
                                    onClick={() => {
                                      setOpenYearPicker(!calenderOpen3);
                                    }}
                                  >
                                    <img src={calender} alt="img" />
                                  </button>
                                );
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                className="ml-2"
                                onClick={() => {
                                  setOpenYearPicker(true);
                                }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <button className="btn absolute">
                          {/* <img src={calender} alt="img" /> */}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {(giveFieldOfSelectedCaseType("hasLowerCourt") ||
            giveFieldOfSelectedCaseType("lower_court_name") ||
            giveFieldOfSelectedCaseType("case_number") ||
            giveFieldOfSelectedCaseType("court_order_date") ||
            giveFieldOfSelectedCaseType("court_district_name") ||
            giveFieldOfSelectedCaseType("court_ia_number") ||
            giveFieldOfSelectedCaseType("court_ia_year") ||
            giveFieldOfSelectedCaseType("main_prayer") ||
            giveFieldOfSelectedCaseType("interim_prayer") ||
            giveFieldOfSelectedCaseType("pleadings")) && (
            <>
              <div className="row ">
                <div className="col-lg-3 my-auto">
                  <h6>Case Details</h6>
                </div>
                <div className="col-lg-9 border_left">
                  {giveFieldOfSelectedCaseType("hasLowerCourt") && (
                    <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                      <Tooltip title="Enter Has Lower court">
                        <p className="">Has Lower Court</p>
                      </Tooltip>

                      <div className="form-inline my-2 header_search header_search_calender relative my-lg-0 relative form-2">
                        <Checkbox
                          name="hasLowerCourt"
                          type="checkbox"
                          checked={details?.hasLowerCourt}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  )}

                  {giveFieldOfSelectedCaseType("lower_court_name") &&
                    (details?.hasLowerCourt ||
                      returnCaseTypeID() === "627a501b9da4621d5a0aaaab") && (
                      <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                        <Tooltip title="Enter Lower Court Name">
                          <p className="">Lower Court Name</p>
                        </Tooltip>

                        <div className="form-inline my-2 header_search header_search_calender relative my-lg-0 relative form-2">
                          <input
                            className="form-control mr-sm-2"
                            type="search"
                            aria-label="Search"
                            name="lower_court_name"
                            value={details?.lower_court_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}

                  {(details?.hasLowerCourt ||
                    returnCaseTypeID() === "627a501b9da4621d5a0aaaab") && (
                    <>
                      {" "}
                      {inputFiledData?.map((item, index) => {
                        return (
                          <>
                            {giveFieldOfSelectedCaseType(item?.name) && (
                              <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                                {/* <div className="d-md-flex d-block w-100 justify-content-between mt-4"> */}
                                <Tooltip title={`Enter ${item?.label}`}>
                                  <p className="">{item?.label}</p>
                                </Tooltip>

                                <div className="form-inline my-2 header_search header_search_calender relative my-lg-0 relative form-2">
                                  {item?.type === "input" && (
                                    <input
                                      className="form-control shadow-lg mr-sm-2"
                                      type="search"
                                      aria-label="Search"
                                      name={item?.name}
                                      value={details?.[item?.name]}
                                      onChange={handleChange}
                                    />
                                  )}

                                  {item?.type === "date" && (
                                    <LocalizationProvider
                                      style={{
                                        width: "100%",
                                      }}
                                      className="w-100 shadow-lg"
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <DatePicker
                                        value={details?.[item?.name]}
                                        open={calenderOpen2}
                                        onOpen={() => {
                                          setCAlerderOpen2(true);
                                        }}
                                        onClose={() => {
                                          setCAlerderOpen2(false);
                                        }}
                                        ref={calenderRef}
                                        name={item?.name}
                                        onChange={(newValue) => {
                                          setDetails({
                                            ...details,
                                            [item?.name]: newValue,
                                          });
                                        }}
                                        components={{
                                          OpenPickerIcon: () => {
                                            return (
                                              <button
                                                className="btn "
                                                onClick={() => {
                                                  setCAlerderOpen2(
                                                    !calenderOpen2
                                                  );
                                                }}
                                              >
                                                <img src={calender} alt="img" />
                                              </button>
                                            );
                                          },
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            className="ml-2"
                                            onClick={() => {
                                              item?.name === "court_order_date"
                                                ? setCAlerderOpen2(true)
                                                : setCAlerderOpen3(true);
                                            }}
                                            {...params}
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )}

                                  {item?.type === "year" && (
                                    <LocalizationProvider
                                      style={{
                                        width: "100%",
                                      }}
                                      className="w-100 shadow-lg"
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <DatePicker
                                        value={details?.[item?.name]}
                                        views={["year"]}
                                        open={calenderOpen3}
                                        onOpen={() => {
                                          setCAlerderOpen3(true);
                                        }}
                                        onClose={() => {
                                          setCAlerderOpen3(false);
                                        }}
                                        ref={calenderRef}
                                        name={item?.name}
                                        onChange={(newValue) => {
                                          setDetails({
                                            ...details,
                                            [item?.name]: newValue,
                                          });
                                        }}
                                        components={{
                                          OpenPickerIcon: () => {
                                            return (
                                              <button
                                                className="btn "
                                                onClick={() => {
                                                  setCAlerderOpen3(
                                                    !calenderOpen3
                                                  );
                                                }}
                                              >
                                                <img src={calender} alt="img" />
                                              </button>
                                            );
                                          },
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            className="ml-2"
                                            onClick={() => {
                                              item?.name === "court_order_date"
                                                ? setCAlerderOpen2(true)
                                                : setCAlerderOpen3(true);
                                            }}
                                            {...params}
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </>
                  )}

                  {inputFiledData2?.map((item, index) => {
                    return (
                      <>
                        {giveFieldOfSelectedCaseType(item?.name) && (
                          <div className="d-md-flex d-block w-100 justify-content-between mt-4">
                            <Tooltip title={`Enter ${item?.label}`}>
                              <p className="">{item?.label}</p>
                            </Tooltip>
                            <div className="form-inline my-2 header_search header_search_calender relative my-lg-0 relative form-2">
                              {item?.type === "input" && (
                                <input
                                  className="form-control shadow-lg mr-sm-2"
                                  type="search"
                                  aria-label="Search"
                                  name={item?.name}
                                  value={details?.[item?.name]}
                                  onChange={handleChange}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          )}

          <div className="text-md-end mb-5 mt-3">
            {/* <Link to="/draft"> */}
            <button className="btn primary_btn p-draft" onClick={submitData}>
              Preview Draft
              <img src={btnArrow} alt="img" />
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
