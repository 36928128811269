import "./EmailNPS.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ratingName } from "../../redux/audio.slice";
import { useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

function EmailNPS() {
    const [loading, setLoading] = useState(false);
    const { rating, id } = useParams();
    const disPatch = useDispatch();
    //const [emailMsg, setEmailMsg] = useState("");
    const [emailRating, setEmailRating] = useState(Number(rating));

     useEffect(() => {
        const emailSurvey = {
            id: id,
            emailRating: Number(rating),
            surveyType: "email-nps"
        }
        setLoading(true);
        disPatch(ratingName(emailSurvey));
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    },[id, rating, disPatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // const emailSurvey = {
        //     id: id,
        //     emailMsg: emailMsg,
        //     emailRating: Number(emailRating),
        //     surveyType: "email-nps"
        // }
        // setLoading(true);
        // disPatch(ratingName(emailSurvey));
        // setTimeout(() => {
        //     setLoading(false);
        // }, 5000);
    };

    return (
        <>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress style={{ color: "#138085" }} />
                </Box>
            ) : (
                <div id="page">
                    <form onSubmit={handleSubmit}>
                        <div className="container">
                            <div className="content-block">
                                <div className="logo d-flex align-items-center justify-content-center">
                                        <svg width="53" height="54" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="52" height="53" rx="3.5" fill="#6093EA" />
                                            <g filter="url(#filter0_d_19_2380)">
                                                <path d="M20.009 40H14.2419L23.0292 14.5455H29.9646L38.7394 40H32.9724L26.5963 20.3622H26.3975L20.009 40ZM19.6485 29.9947H33.2707V34.1957H19.6485V29.9947Z" fill="white" />
                                            </g>
                                            <rect x="0.5" y="0.5" width="52" height="53" rx="3.5" stroke="#F1F6FF" />
                                            <defs>
                                                <filter id="filter0_d_19_2380" x="11.2419" y="12.5454" width="38.4975" height="39.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dx="4" dy="5" />
                                                    <feGaussianBlur stdDeviation="3.5" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19_2380" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19_2380" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg> &nbsp;
                                        <h1>ACHME</h1>
                                </div>
                                <div className="content">
                                    <p>
                                        How likely are you to recommend our company to a friend or
                                        colleague?{" "}
                                    </p>
                                    <ul className="ratings">
                                        <li>
                                            <button className={`${emailRating === 0 ? 'button-0' : 'test-0'}`} type="button" onClick={() => { setEmailRating(0) }}>0</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 1 ? 'button-0' : 'test-0'}`} type="button" onClick={() => { setEmailRating(1) }}>1</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 2 ? 'button-0' : 'test-0'}`} type="button" onClick={() => { setEmailRating(2) }}>2</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 3 ? 'button-0' : 'test-0'}`} type="button" onClick={() => { setEmailRating(3) }}>3</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 4 ? 'button-0' : 'test-0'}`} type="button" onClick={() => { setEmailRating(4) }}>4</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 5 ? 'button-0' : 'test-0'}`} type="button" onClick={() => { setEmailRating(5) }}>5</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 6 ? 'button-0' : 'test-0'}`} type="button" onClick={() => { setEmailRating(6) }}>6</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 7 ? 'yellow button-1' : 'yellow test-1'}`} type="button" onClick={() => { setEmailRating(7) }} >7</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 8 ? 'yellow button-1' : 'yellow test-1'}`} type="button" onClick={() => { setEmailRating(8) }} >8</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 9 ? 'green button-2' : 'green test-2'}`} type="button" onClick={() => { setEmailRating(9) }} >9</button>
                                        </li>
                                        <li>
                                            <button className={`${emailRating === 10 ? 'green button-2' : 'green test-2'}`} type="button" onClick={() => { setEmailRating(10) }} >10</button>
                                        </li>
                                    </ul>
                                    <div className="botton-row">
                                        <div className="left-col">
                                            <label>😠 Not Likely</label>
                                        </div>
                                        <div className="right-col">
                                            <label>Most Likely 😍</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex my-2 align-items-start justify-content-end myContectBtn submit">
                                <button type="submit" id='autoSubmit' >
                                    Submit
                                </button>
                            </div>
                        </div>
                        {/* <div className="container">
                            <div className="content-block">
                                <div className="logo d-flex align-items-center justify-content-center">
                                        <svg width="53" height="54" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="52" height="53" rx="3.5" fill="#6093EA" />
                                            <g filter="url(#filter0_d_19_2380)">
                                                <path d="M20.009 40H14.2419L23.0292 14.5455H29.9646L38.7394 40H32.9724L26.5963 20.3622H26.3975L20.009 40ZM19.6485 29.9947H33.2707V34.1957H19.6485V29.9947Z" fill="white" />
                                            </g>
                                            <rect x="0.5" y="0.5" width="52" height="53" rx="3.5" stroke="#F1F6FF" />
                                            <defs>
                                                <filter id="filter0_d_19_2380" x="11.2419" y="12.5454" width="38.4975" height="39.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dx="4" dy="5" />
                                                    <feGaussianBlur stdDeviation="3.5" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19_2380" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19_2380" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg> &nbsp;
                                        <h1>ACHME</h1>
                                </div>
                                <div className="content">
                                    <p>What do you love about our product or service?</p>
                                </div>
                                <div className="message-box">
                                    <textarea
                                        defaultValue={""}
                                        name='emailMsg'
                                        onChange={(e) => {
                                            setEmailMsg(e.target.value)
                                        }}
                                    />
                                    <div className="d-flex my-2 align-items-start justify-content-end myContectBtn submit">
                                        <button type="submit" id='autoSubmit' >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </form>
                </div>
            )}
        </>
    )
}

export default EmailNPS