import React, { useEffect, useState } from "react";
import Process from "../CommonComponent/Process/Process";
import "./draft.scss";
import backArrow from "../../assets/image/backArrow.svg";
import btnArrow from "../../assets/image/btnArrow.svg";
import smallSpeaker from "../../assets/image/smallSpeaker.svg";
import { Link, useNavigate } from "react-router-dom";
import structure from "../../assets/image/structure1.svg";
import largeLogo from "../../assets/image/largeLogo.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { mediaPath } from "../../API";
import { set_craeteAndUpadteDraft_data_from_local_storage } from "../../redux/audio.slice";
import { RedirectUser } from "../../utils/utils";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { Box, CircularProgress } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
function Draft() {
  const { craeteAndUpadteDraft_data } = useSelector((s) => s.audioState);
  const [loading, setLoading] = useState(true);

  const disPatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    RedirectUser(navigate);

    disPatch(set_craeteAndUpadteDraft_data_from_local_storage());
  }, []);

 
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }


  return (
    <div>
      <img src={structure} alt="img" className="structure1" />
      <img src={largeLogo} alt="img" className="structure2" />
      <Process type="draft" />
      <section className="draft">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Draft Preview</h2>
            </div>
          </div>
          {/* <div className="inner_section">
            <div class="alert alert-warning" role="alert">
              If the draft preview doesn’t load please try refreshing your
              browser
            </div>
          </div> */}

          <div className="iframeWrapper">
            {craeteAndUpadteDraft_data !== null && (
              <div
                style={{
                  margin: "auto",
                  width: "100%",
                  padding: "0px 10px 0px 10px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "620px",
                  textAlign: "center",
                }}
              >
                <Document
                  options={{ workerSrc: "/pdf.worker.js" }}
                  file={mediaPath(
                    craeteAndUpadteDraft_data?.draft_PDF_FileName
                  )}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {loading ? (
                    <div>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </div>
                  ) : (
                    <>
                      {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map((page) => (
                          <Page loading={false} scale={1.2} pageNumber={page} />
                        ))}
                    </>
                  )}
                </Document>
              </div>
            )}
          </div>

          <div className="row mb-5 mt-4">
            <div className="col-12 d-md-flex d-block justify-content-end ">
              <Link to="/home" style={{ marginRight: "10px" }}>
                <button className="back_btn btn dictate-1">
                  <img src={backArrow} aly="img" />
                  Back
                </button>
              </Link>
              <Link to="/audio">
                <button className="btn primary_btn dictate-2">
                  <img
                    src={smallSpeaker}
                    alt="img"
                    style={{ width: "20px", marginTop: "-5px" }}
                  />
                  Dictate Draft
                  <img src={btnArrow} alt="img" />
                </button>
              </Link>
            </div>
          </div>
        </div>
        <h2></h2>
      </section>
    </div>
  );
}

export default Draft;
