import { configureStore } from "@reduxjs/toolkit";
import audioSlice from "./audio.slice";

import IndexSlice from "./IndexSlice";

const Store = configureStore({
  reducer: {
    Index: IndexSlice,
    audioState: audioSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default Store;
