import React, { useEffect, useRef, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Process from "../CommonComponent/Process/Process";
import "./audio.scss";
import pause from "../../assets/image/pause.svg";
import upload from "../../assets/image/upload.svg";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../../assets/image/backArrow.svg";
import btnArrow from "../../assets/image/btnArrow.svg";
import wavesurfer from "wavesurfer.js";
import {
  convertSecondsToTime,
  generateId,
  RedirectUser,
} from "../../utils/utils";
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js";
import { FaStop } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getAllAudioFiles,
  reorderAudioFiles,
  setCurrentAudio,
  set_global_recording_state,
  set_global_right_list_audio_state,
  uploadAudioFiles,
  onRecordingPauseSendFilesToBackend,
  set_onRecordingPauseSendFilesToBackend_data,
  setrecordedFileInRedux,
  setcounter,
} from "../../redux/audio.slice";
import Error from "../Error/Error";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MyBackdropLoader from "../Loaders/MyBackdropLoader";
import { Skeleton } from "@mui/material";
import AlertModal from "../Modals/AlertModal";
import ListAudioPlayer from "./ListAudioPlayer";
import { audioPAth } from "../../API";

const grid = 10;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const QuoteItem = styled.div`
  width: 400px;
  border: none;
  margin-bottom: ${grid}px;
  background-color: transparent;
  padding: ${grid}px;
`;

const QuoteItem2 = styled.div`
  max-width: 200px;
  border: none;
  margin-bottom: ${grid}px;
  background-color: transparent;
  padding: ${grid}px;
`;

const NoaudioCard = () => {
  return (
    <QuoteItem2>
      {/* ********** for recording pop-up screen use (active_audio) class ***********/}
      <div className={`card audioCard my-4 `}>
        <h5>No audio found</h5>
      </div>
    </QuoteItem2>
  );
};

const RecordingAnimation = ({ paused }) => {
  const renderAnimation = () => {
    const bar = document.querySelectorAll(".bar");
    for (let i = 0; i < bar.length; i++) {
      bar.forEach((item, j) => {
        // Random move
        if (paused) {
          item.style.animationDuration = `0s`; // Change the numbers for speed / ( max - min ) + min / ex. ( 0.5 - 0.1 ) + 0.1
        } else {
          item.style.animationDuration = `${
            Math.random() * (0.7 - 0.2) + 0.2
          }s`; // Change the numbers for speed / ( max - min ) + min / ex. ( 0.5 - 0.1 ) + 0.1
        }
      });
    }
  };
  useEffect(() => {
    renderAnimation();
  }, [paused]);
  return (
    <div className="recordingWave p-3">
      <div className="sound-wave">
        {Array.from(Array(50).keys()).map((item, index) => {
          return <div key={index} className="bar"></div>;
        })}
      </div>
      <h3 className="text-center text-muted mt-3">
        {paused ? "Recording Paused..." : "Recording..."}
      </h3>
    </div>
  );
};

const SkeleonLoader = () => {
  return (
    <div className="card audioCard mb-4 mt-2">
      <Skeleton
        animation="wave"
        sx={{ bgcolor: "grey.200" }}
        variant="rectangular"
        width={"100%"}
        height={118}
      />
    </div>
  );
};

const AudioCards = React.memo(({ quote, index }) => {


  return (
    <Draggable id="parent" draggableId={quote?._id} index={index}>
      {(provided) => (
        <>
          <QuoteItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <ListAudioPlayer quote={quote} index={index} file={quote} />
          </QuoteItem>
        </>
      )}
    </Draggable>
  );
});


function AudioForSafari() {
  const wavesurferRef = useRef(null);
  const timelineRef = useRef(null);
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [wavesurferObj, setWavesurferObj] = useState();
  const [playing, setPlaying] = useState(true); // to keep track whether audio is currently playing or not // to control the zoom level of the waveform
  const [duration, setDuration] = useState(0); // duration is used to set the default region of selection for trimming the audio
  const [total_duration, settotal_duration] = useState(0);
  const [recordingStart, setRecordingStart] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errMsg, seterrMsg] = useState(null);
  const [value, setValue] = useState(null);
  const [hour, sethour] = useState("00");
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const {
    uploadAudioFiles_status,
    uploadAudioFiles_error,
    getAllAudioFiles_data,
    getAllAudioFiles_status,
    removeAudioFiles_status,
    previousWebsurferRef,
    caseInfoId,
    uploadFileProgress,
    onRecordingPauseSendFilesToBackend_data,
    global_left_list_audio_state,
  } = useSelector((s) => s.audioState);
  const [state, setState] = useState({ quotes: [] });
  const [loading, setLoading] = useState(false);
  const [recording_status, setRecording_status] = useState("idle");
  const { recording_start_counter } = useSelector((s) => s.audioState);
  const { status, error } = useReactMediaRecorder({
    video: false,
    audio: true,
    echoCancellation: true,
    askPermissionOnMount: true,
  });
  const [store_recorded_blobs, setStore_recorded_blobs] = useState([]);
  const [just_recorded_file, setJust_recorded_file] = useState(null);
  const [recorded_file_playing_status, setRecorded_file_playing_status] =
    useState("idle");
  const [mainPlayer_mode, setMainPlayer_mode] = useState("idle");
  const [showWaveSurfer, setShowWaveSurfer] = useState(false);
  const [safariNavigator, setSafariNavigator] = useState(null);
  const [latestFile, setLatestFile] = useState(null);
  const navigate = useNavigate();
  let chunks2 = [];
  const [recordedFiles, setRecordedFiles] = useState([]);
  const [loading2, setLoading2] = useState(false);

  // -variables

  const handleButtonClick = () => {
    inputFile.current.click();
  };

  const redirectToNextPage = () => {
    if (recording_status === "paused" || recording_status === "recording") {
      safariNavigator?.state !== "inactive" && safariNavigator?.stop();
      setRecording_status("idle");
      FinalStopRecordingForSafari();
    }

    dispatch(setCurrentAudio(null));
    dispatch(set_onRecordingPauseSendFilesToBackend_data(null));

    previousWebsurferRef !== null && previousWebsurferRef?.stop();

    setRecordingStart(false);
    setFile(null);

    navigate("/submission");
  };

  const handleFileUpload = (e) => {
    setFile(null);
    setValue(e.target.files[0]);
    setPlaying(true);
    console.log(e.target.files[0]);

    //accept .wav, .mp3, .mpg file only
    if (
      e.target.files[0]?.type === "audio/wav" ||
      e.target.files[0]?.type === "audio/x-wav" ||
      e.target.files[0]?.type === "audio/mp3" ||
      e.target.files[0]?.type === "audio/mpeg"
    ) {
      const form = new FormData();
      form.append("audio", e.target.files[0]);
      form.append("caseInfoId", caseInfoId);

      dispatch(uploadAudioFiles(form));
    } else {
    
      toast.error(
        "Please upload a valid audio file.Upload only .wav, .mp3, .mpg file"
      );
     
    }
 
  };


 // FOR DRAG AND DROP
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(
      state.quotes,
      result.source.index,
      result.destination.index
    );

    let payload = {
      caseInfoId: caseInfoId,
      fileId: quotes[result.source.index]?._id,
      otherFileId: quotes[result.destination.index]?._id,
    };

    dispatch(reorderAudioFiles(payload));

    setState({ quotes });
  }

  //FOR PLAY PAUSE THE MAIN AUDIO PLAYER
  const handlePlayPause = (e) => {
    wavesurferObj?.playPause();
    setPlaying(!playing);
  };

//PLAY PAUSE THE RECORDED AUDIO
  const PlayPauseJustRecordedFile = () => {
    setMainPlayer_mode("player");

    if (just_recorded_file !== null) {
      if (recorded_file_playing_status === "idle") {
        setFile(just_recorded_file);

        setRecorded_file_playing_status("playing");
      } else {
        setRecorded_file_playing_status("paused");
      }
      wavesurferObj?.playPause();
      setPlaying(!playing);
    }
  };




  // CHECK IF THE AUDIO IS RECORDING IS AVAILABLE BU BROWSER OR NOT
  const checkWindowAudioContextAvailable_orNOt = () => {
    let temp = false;
    if (!(window.AudioContext || window.webkitAudioContext)) {
      seterrMsg(
        "Your browser does not support window.Audiocontext. This is needed for this demo to work. Please try again in a differen browser."
      );
      temp = true;
    }

    return temp;
  };

  // CHEK ANY RECORDING RELATED ERRORS
  const check_Audio_recording_error = () => {
    let temp;
    setAlertModal(false);
    if (error?.length > 0) {
      console.log(error,"error")
      if (error === "permission_denied") {
        seterrMsg(
          "You denied the permission to use your microphone. Please try again."
        );
      }

      if (error === "no_specified_media_found") {
        seterrMsg("No media recording device found. Please try again.");
      }

      setAlertModal(true);
      temp = true;
    }

    return temp;
  };


 //AFTER RECORDING PAUSE SEND FILE TO BACKEND => THEN PLAY IT
  const sendFileToBackendAfterPAuse = (type) => {
    if (type === "send_after_pause") {
      const form = new FormData();
      recordedFiles?.map((item) => {
        form.append("audio_files", item);
      });

     
      dispatch(onRecordingPauseSendFilesToBackend(form));
    }
  };

  const updateRecorededFiles = (file) => {
    let temp2 = [...recordedFiles, file];

    setRecordedFiles(temp2);
    dispatch(setrecordedFileInRedux(temp2));

    return temp2;
  };


  //START THE RECORDING COUNTER
  const recordingStartCounter = () => {
    dispatch(setcounter(recording_start_counter + 1));
  };

  // START MEDIA RECORDING
  const handleStartRecordingForSafari = () => {
    dispatch(setCurrentAudio(null)); //clear prev. data
    setMainPlayer_mode("recording"); //put player in recording mode
    previousWebsurferRef !== null && previousWebsurferRef?.stop();

    if (checkWindowAudioContextAvailable_orNOt()) return; //recording supports or not by browser

    if (check_Audio_recording_error()) return; // micronphone accessable or not

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        let navigatorInstance = new MediaRecorder(stream);

        navigatorInstance.ondataavailable = (e) => {
          
          chunks2.push(e.data);
        };

      

        navigatorInstance.onstop = (e) => {
          let audioBlob = new Blob(chunks2, {
            type: "audio/wav",
          });
          

          const audioUrl = URL.createObjectURL(audioBlob); //convert blob to URL

          let temp = [...store_recorded_blobs, audioBlob];
          setStore_recorded_blobs(temp);

      
          let mergedFiles = [];

          async function conevrtTofile() {
            let file2 = await fetch(audioUrl)
              .then((r) => r.blob())
              .then(
                (blobFile) =>
                  new File([blobFile], `${generateId()}.wav`, {
                    type: "audio/wav",
                    lastModified: new Date().getTime(),
                  })
              );

            setLatestFile(file2);
                       mergedFiles = updateRecorededFiles(file2);
           
          }
          conevrtTofile();
        
          // For stoping the recording icon in the chrome
          let tracks = stream.getTracks();
          tracks.forEach(function (track) {
            track.stop();
          });
          // OnStopMakeFile(store_recorded_blobs);
        };
        navigatorInstance.onerror = (e) => {};
        navigatorInstance.start();
        // errorCallback
        setSafariNavigator(navigatorInstance);
        setRecording_status("recording");
        setFile(null);
        setPlaying(true);
        setRecordingStart(true);
        setIsActive(true);

        // setStatus("recording");
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  
  //PAUSE RECORDING
  const handleRecordingPauseForSafari = () => {
    recordingStartCounter();
    safariNavigator.stop();
    setRecording_status("paused");
    setIsActive(false);
  };

  //AFTER STOP RESET STATES
  const resetAllAfterFinalSubmit = () => {
    setRecording_status("idle");
    setRecordingStart(false);
    setIsActive(false);
    setFile(null);
    setMainPlayer_mode("idle");
    setJust_recorded_file(null);
    setStore_recorded_blobs([]);
    setRecordedFiles([]);
    dispatch(set_onRecordingPauseSendFilesToBackend_data(null));
  };

  // STOP THE RECORDING
  const FinalStopRecordingForSafari = async () => {
    wavesurferObj?.empty();
    setCounter(0);
    if (safariNavigator?.state !== "inactive") {
      safariNavigator?.stop();
    }
    setLoading2(true);
    let margedFileURl = "";
    if (recording_start_counter > 0) {
      let sliptAudioPath = onRecordingPauseSendFilesToBackend_data?.split(
        "public/uploads/audio/"
      )[1];
      margedFileURl = audioPAth(sliptAudioPath);
      // ... do something with the file or return it
      // read file form url and convert to file--
      let file3 = await fetch(margedFileURl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], `${generateId()}.wav`, {
              type: "audio/wav",
              lastModified: new Date().getTime(),
            })
        );

      // downloadFile(margedFileURl);
      setLoading2(false);
      const form = new FormData();
      form.append("audio", file3);
      form.append("caseInfoId", caseInfoId);
      dispatch(uploadAudioFiles(form));
      resetAllAfterFinalSubmit();
    }
  };

  // -functions

  //ON EVERY PAUSE SEND THE RECORDED FILE TO BACKEND FOR MARGEING
  useEffect(() => {
    if (
      recording_start_counter === 0 &&
      latestFile !== null &&
      onRecordingPauseSendFilesToBackend_data === null
    ) {
      const form = new FormData();
      form.append("audio", latestFile);
      form.append("caseInfoId", caseInfoId);
      dispatch(uploadAudioFiles(form));
      resetAllAfterFinalSubmit();
    }
  }, [recording_start_counter, latestFile]);


  useEffect(() => {
    if (recordedFiles?.length > 0) {
      sendFileToBackendAfterPAuse("send_after_pause");
    }
  }, [recordedFiles]);

  //READ THE MARGE FILE FROM BACKEND
  useEffect(() => {
    if (onRecordingPauseSendFilesToBackend_data !== null) {
      let sliptAudioPath = onRecordingPauseSendFilesToBackend_data?.split(
        "public/uploads/audio/"
      )[1];
      setJust_recorded_file(audioPAth(sliptAudioPath));
      setRecorded_file_playing_status("idle");
    }
  }, [onRecordingPauseSendFilesToBackend_data]);

  //GET AUDIO FILES FROM BACKEND
  useEffect(() => {
    caseInfoId !== null && dispatch(getAllAudioFiles(caseInfoId));
  }, [caseInfoId]);

  //IF THERE IS NO CASEINFO ID IN THE LOCAL STORAGE => REDIRCET TO HOME
  useEffect(() => {
    RedirectUser(navigate);
    setFile(null);
    dispatch(setCurrentAudio(null));
  }, []);

  useEffect(() => {
    if (getAllAudioFiles_data !== null) {
      setState({ quotes: getAllAudioFiles_data });
      setValue(null);
    }
  }, [getAllAudioFiles_data]);

  useEffect(() => {
    if (uploadAudioFiles_error !== null) {
      seterrMsg(uploadAudioFiles_error);
    }
  }, [uploadAudioFiles_error]);


  // INITIALIZE THE WAVESURFER FOR MEDIA PLAYER
  useEffect(() => {
    if (wavesurferRef.current && !wavesurferObj) {
      setWavesurferObj(
        wavesurfer.create({
          container: "#waveform",
          scrollParent: true,
          autoCenter: true,
          cursorColor: "#138085",
          loopSelection: true,
          waveColor: "#138085",
          progressColor: "#98ACAD",
          responsive: true,
          fillParent: false,

          plugins: [
            // TimelinePlugin.create({
            //   container: "#wave-timeline",
            // }),
            // RegionsPlugin.create({}),
            MicrophonePlugin.create({
              audioContext: {
                sampleRate: 44100,
                channelCount: 2,
                channelCountMode: "explicit",
                channelInterpretation: "speakers",
              },
            }),

            CursorPlugin?.create({
              showTime: true,
              opacity: 1,

              color: "#138085",
              customShowTimeStyle: {
                "background-color": "#000",
                color: "#fff",
                padding: "2px",
                "font-size": "10px",
              },
            }),
          ],
        })
      );
    }
  }, [wavesurferRef, wavesurferObj]);



  // once the file URL is ready, load the file to produce the waveform
  useEffect(() => {
    if (file !== null && file !== undefined && wavesurferObj) {
      setLoading(true);

      file !== null && file !== undefined && wavesurferObj.load(file);
      settotal_duration(0);
      setDuration(0);
    }
  }, [file, wavesurferObj]);


  useEffect(() => {
    if (wavesurferObj) {
      // once the waveform is ready, play the audio
      wavesurferObj.on("ready", () => {
        wavesurferObj.play();
        setLoading(false);
        setMainPlayer_mode("player");
        wavesurferObj.enableDragSelection({}); // to select the region to be trimmed

        // set the duration in local state
      });

      wavesurferObj.on("audioprocess", function () {
        if (wavesurferObj.isPlaying()) {
          var totalTime = wavesurferObj.getDuration(),
            currentTime = wavesurferObj.getCurrentTime(),
            remainingTime = totalTime - currentTime;

          settotal_duration(Math.floor(totalTime));

          setDuration(Math.floor(remainingTime));
          // dispatch(set_current_time(Math.floor(remainingTime)));
        }
      });

      // once audio starts playing, set the state variable to true
      wavesurferObj.on("play", () => {
        setPlaying(false);
      });

      // once audio starts playing, set the state variable to false
      wavesurferObj.on("finish", () => {
        setPlaying(true);
        settotal_duration(Math.floor(wavesurferObj.getDuration()));

        setDuration(Math.floor(0));
      });

      // if multiple regions are created, then remove all the previous regions so that only 1 is present at any given time
      wavesurferObj.on("region-updated", (region) => {
        const regions = region.wavesurfer.regions.list;
        const keys = Object.keys(regions);
        if (keys.length > 1) {
          regions[keys[0]].remove();
        }
      });
    }
  }, [wavesurferObj]);

  // RECORDING COUNTER
  useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        setCounter((counter) => counter + 1);
      }, 900);
    }
    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  useEffect(() => {
    const secondCounter = counter % 60;

    const minuteCounter = Math.floor(counter / 60);
    const hourCounter = Math.floor(minuteCounter / 60);

    let computedSecond =
      String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
    let computedMinute =
      String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

    let computedHour =
      String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;

    setSecond(computedSecond);
    setMinute(computedMinute);
    sethour(computedHour);
  }, [counter]);


  useEffect(() => {
    setShowWaveSurfer(
      file === null || loading || mainPlayer_mode === "recording"
    );
  }, [file, loading, mainPlayer_mode]);

  useEffect(() => {
    if (
      global_left_list_audio_state === "playing"
      // &&      recording_status === "recording"
    ) {
      safariNavigator !== null &&
        safariNavigator?.state === "recording" &&
        handleRecordingPauseForSafari();
    }

    if (recording_status !== "") {
      dispatch(set_global_recording_state(recording_status));
    }


  }, [global_left_list_audio_state, recording_status]);

  useEffect(() => {
    if (global_left_list_audio_state === "playing") {
      if (wavesurferObj?.isPlaying()) {
        wavesurferObj?.pause();
        setPlaying(!playing);
      }
    }
  }, [global_left_list_audio_state]);

  useEffect(() => {
    dispatch(set_global_right_list_audio_state(playing));
  }, [playing]);




  // -useeffects

  return (
    <>
      <MyBackdropLoader
        msg={
          (removeAudioFiles_status === "loading" &&
            "Removing audio files...") ||
          (uploadAudioFiles_status === "loading" && "Uploading audio files...")
        }
        value={
          removeAudioFiles_status === "loading" ||
          uploadAudioFiles_status === "loading" 
        }
        progressVal={uploadFileProgress}
        type={uploadAudioFiles_status === "loading" ? "progress" : "normal"}
      />
      <AlertModal
        open={alertModal}
        handleClose={() => setAlertModal(false)}
        text="Unable to start recording. Please try again."
        pText={errMsg}
      />

      <Process type="audio" />
      <div className="audio">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tag_bg">
              <h5>Audio Recording</h5>
            </div>
            {errMsg !== null && (
              <div className="mt-4">
                <Error msg={errMsg} />
              </div>
            )}
          </div>
          <div className="row audio_sec">
            <div className="col-xl-4 mt-5">
              <h6>Recording List</h6>

              {getAllAudioFiles_status === "loading" &&
              getAllAudioFiles_data === null ? (
                <>
                  {" "}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {[1, 2, 4, 4].map((item) => {
                      return <SkeleonLoader key={item} />;
                    })}
                  </Box>
                </>
              ) : (
                <>
                  {" "}
                  {state.quotes?.length > 0 ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="list">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            {...provided.dragHandleProps}
                          >
                            {state.quotes.map((item, index) => {
                              return (
                                <AudioCards
                                  playPause={handlePlayPause}
                                  quote={item}
                                  index={index}
                                  key={item?._id}
                                  duration={total_duration}
                                  current_duration={duration}
                                  playing={playing}
                                  file={file}
                                  loading={loading}
                                  timelineRef={timelineRef}
                                  wavesurferRef={wavesurferRef}
                                />
                              );
                            })}
                            
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <NoaudioCard />
                  )}
                </>
              )}

              <button
                disabled={uploadAudioFiles_status === "loading"}
                onClick={handleButtonClick}
                className="btn primary_btn upload-button "
              >
                {" "}
                <img src={upload} alt="img" style={{ width: "40px" }} />
                Upload Audio
              </button>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                accept="audio/*"
                onChange={handleFileUpload}
                key={value}
              />
            </div>

            {/** AUDIO TIMELINE DISPLAY */}
            <div className="col-xl-8">
              <div className="card main_audio">
                <div className="card-body">
                  {(mainPlayer_mode === "idle" ||
                    mainPlayer_mode === "recording") && (
                    <>
                      {recording_status === "idle" && <h2>Start Recording</h2>}
                      {recordingStart && (
                        <>
                          {(recording_status === "recording" ||
                            recording_status === "paused") && (
                            <h2>
                              <span>
                                {hour}:{minute}:{second}
                              </span>
                            </h2>
                          )}

                          <RecordingAnimation
                            paused={recording_status === "paused"}
                          />
                        </>
                      )}
                    </>
                  )}

                  {loading ? (
                    <>
                      <div className="text-center p-2">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="text-muted mx-2">
                          Please wait while your audio is loading
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {mainPlayer_mode === "player" && (
                        <h2>
                          <span>{convertSecondsToTime(duration)}</span>
                        </h2>
                      )}
                    </>
                  )}


                {/*  ********MAIN PLAYER START FROM HERE******* */}
                  <div
                 
                    ref={wavesurferRef}
                    className={
                      showWaveSurfer
                        ? "wave_class d-none "
                        : " wave_class w-100"
                    }
                    id="waveform"
                  />
                  <div
                   
                    className={
                      showWaveSurfer
                        ? " d-none  wave_class"
                        : "wave_class w-100"
                    }
                    ref={timelineRef}
                    id="wave-timeline"
                  />


                  {file !== null && (
                    <div className="d-flex justify-content-between">
                      <span style={{ color: " #B3AFAF" }}>00:00</span>
                      <span style={{ color: " #B3AFAF" }}>
                        {convertSecondsToTime(total_duration)}
                      </span>
                    </div>
                  )}

                  <div className="pause-play pt-4">
                    <div className="text-center">
                      <button
                        disabled={
                          (file === null && just_recorded_file === null) ||
                          recording_status === "recording"
                        }
                        className="btn"
                        onClick={PlayPauseJustRecordedFile}
                      >
                        {playing ? (
                          <AiFillPlayCircle className="playPauseBtn" />
                        ) : (
                          <AiFillPauseCircle className="playPauseBtn" />
                        )}

                        {/* <img src={start} alt="img" className="small_icon" /> */}
                      </button>
                      <p>{playing ? "Play" : "Pause"} Audio</p>
                    </div>
                    <div className="text-center">
                      {(recording_status === "idle" ||
                        recording_status === "stopped") && (
                        <button
                          onClick={() => handleStartRecordingForSafari()}
                          className="btn"
                        >
                          <FaStop className="record_btn " />
                        </button>
                      )}

                      {recording_status === "paused" && (
                        <button
                          disabled={!playing}
                          onClick={handleStartRecordingForSafari}
                          className="btn"
                        >
                          <FaStop className="record_btn " />
                        </button>
                      )}

                      {recording_status === "recording" && (
                        <button
                          disabled={!playing}
                          onClick={handleRecordingPauseForSafari}
                          className="btn"
                        >
                          <AiFillPauseCircle className="record_btn text-danger" />
                        </button>
                      )}

                      <p>
                        {recording_status === "idle" ||
                        recording_status === "stopped"
                          ? "Start/Pause"
                          : recording_status === "paused"
                          ? "Resume"
                          : "Pause"}{" "}
                        Recording
                      </p>

                      {/* <p>Start/Stop Recording</p> */}
                    </div>
                    <div className="text-center">
                      <button
                        className="btn"
                        onClick={FinalStopRecordingForSafari}
                        disabled={
                          !recordingStart ||
                          !playing ||
                          uploadAudioFiles_status === "loading"
                        }
                      >
                        <img src={pause} alt="img" className="small_icon" />
                      </button>
                      <p>Stop Recording</p>
                    </div>
                  </div>
                    {/*  ********MAIN PLAYER ENDS HERE******* */}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5 mt-4">
            <div className="col-12 d-md-flex d-block justify-content-end">
              <Link to="/draft" style={{ marginRight: "10px" }}>
                <button className="back_btn btn ">
                  <img src={backArrow} aly="img" />
                  Back
                </button>
              </Link>

              <button
                onClick={redirectToNextPage}
                className="btn primary_btn px-5"
                disabled={recording_status !== "idle"}
              >
                Proceed
                <img src={btnArrow} alt="img" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AudioForSafari;
