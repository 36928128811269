import moment from "moment";
import shortid from "shortid";

export const generateId = () => {
  return shortid.generate();
};

export const convertSecondsToTime = (seconds) => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - hours * 3600) / 60);
  let sec = seconds - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (sec < 10) {
    sec = "0" + sec;
  }
  return hours + ":" + minutes + ":" + sec;
};

var BASE64_MARKER = ";base64,";

export function convertDataURIToBinary(dataURI) {
  console.log(dataURI, "datauri");
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));
  let i = 0;

  for (i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

//Regex
export function validEmail(elementValue) {
  // var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  var emailPattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return emailPattern.test(elementValue);
}

export function validPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}
export function checkAmnt(str) {
  var re = /^[0-9]*$/;
  return re.test(str);
}

export function checkName(str) {
  var re = /^[a-zA-Z ]+$/;
  console.log(re.test(str));
  return re.test(str);
}

export function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...(READ MORE)";
  } else {
    return str;
  }
}

export function truncateString2(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export const formatPhoneNumber = (input) => {
  if (!input || isNaN(input)) return "";
  if (typeof input !== "string") input = input.toString();
  return input.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1 $2-$3-$4");
};

//is empty check
export const isEmpty = (obj) => {
  if (obj === null || obj === undefined || obj === {}) return true;
  return false;
};

// export const uniqBy = (arr, key) => {
//   return loadash?.uniqBy(arr, key);
// };

export const ScrollToTop = () => {
  //scroll to top with smooth
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const RedirectUser = (navigate) => {
  let getCaseInfoId = localStorage.getItem("caseInfoID");

  if (
    getCaseInfoId !== null &&
    getCaseInfoId !== undefined &&
    getCaseInfoId !== ""
  ) {
    return;
  } else {
    navigate("/");
  }
};

export const validateInput=(input)=>{
  let res=false;
  if(input?.length===0 || input==="" || input===null || input===undefined){
    res=true;
  }

  return res;
}

//detect browser refresh
export const isBrowserRefresh = () => {
  let getPathname = localStorage.getItem("pathname");
  if (getPathname !== null && getPathname !== undefined && getPathname !== "") {
    return true;
  } else {
    return false;
  }
};

//

export const getYearLastDAte=()=>{
  const d=new Date();

  let year=d.getFullYear();
  //get last date of the year
  let lastDate=new Date(year,11,31);
  return moment(lastDate).format("YYYY-MM-DD");

}

export const getYearFirstDate=()=>{
  const d=new Date();

  let year=d.getFullYear();
  //get first date of the year
  let firstDate=new Date(year,0,1);
  return moment(firstDate).format("YYYY-MM-DD");

}

export const detectBrowser=()=>{
  var sBrowser,
  sUsrAg = navigator.userAgent;

// The order matters here, and this may report false positives for unlisted browsers.

if (sUsrAg.indexOf("Firefox") > -1) {
  sBrowser = "firefox";
  // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
  sBrowser = "Samsung_Internet";
  // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
  sBrowser = "opera";
  // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
} else if (sUsrAg.indexOf("Trident") > -1) {
  sBrowser = "Microsoft_Internet_Explorer";
  // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
} else if (sUsrAg.indexOf("Edge") > -1) {
  sBrowser = "Microsoft_Edge";
  // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
} else if (sUsrAg.indexOf("Chrome") > -1) {
  sBrowser = "chrome";
  // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
} else if (sUsrAg.indexOf("Safari") > -1) {
  sBrowser = "safari";
  // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
} else {
  sBrowser = "unknown";
}

return sBrowser;
}

export function ConcatenateBlobs (blobs,type) {
  console.log(blobs,type,"tt")
  var buffers = [];
let blob=""
  var index = 0;

  function readAsArrayBuffer() {
      if (!blobs[index]) {
          return concatenateBuffers();
      }
      var reader = new FileReader();
      reader.onload = function(event) {
          buffers.push(event.target.result);
          index++;
          readAsArrayBuffer();
      };
      reader.readAsArrayBuffer(blobs[index]);
  }

  readAsArrayBuffer();

  function concatenateBuffers() {
      var byteLength = 0;
      buffers.forEach(function(buffer) {
          byteLength += buffer.byteLength;
      });
      
      var tmp = new Uint16Array(byteLength);
      var lastOffset = 0;
      buffers.forEach(function(buffer) {
          // BYTES_PER_ELEMENT == 2 for Uint16Array
          var reusableByteLength = buffer.byteLength;
          if (reusableByteLength % 2 != 0) {
              buffer = buffer.slice(0, reusableByteLength - 1)
          }
          tmp.set(new Uint16Array(buffer), lastOffset);
          lastOffset += reusableByteLength;
      });

       blob = new Blob([tmp.buffer], {
          type: type
      });

      console.log()

     
  }


  return blob;
}


export const downloadFile = (audioUrl) => {
  const a=document.createElement("a");
  a.href=audioUrl;
  a.download="audio.wav";
  a.click();
}