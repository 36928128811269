import axios from "axios";


// const backendUrl='https://writtlyai-admin.dedicateddevelopers.us' // DEVELOPMENT

//const backendUrl='http://localhost:1574' // Local

const backendUrl='' // LIVE URL

// BASE URL FOR API CALL
export const baseURL = `${backendUrl}/api/`; 

// LOAD PDF FROM THIS PATH -- JUST PASS THE PDF NAME HERE
export const mediaPath = (media) => {
  return `${backendUrl}/uploads/case-info/${media}`;
};

// LOAD AUDIO FROM THIS PATH -- JUST PASSING THE AUDIO PATH HERE
export const audioPAth=(audio)=>{
  return `${backendUrl}/uploads/audio/${audio}`;
}






// CRAETE AXIOS FOR NETWORK CALL

let axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  async function (config) {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token !== null || token !== undefined) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);




export default axiosInstance;
